import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TCustomFieldList } from '../custom-fields.model';

@Component({
  selector: 'pp-custom-field-multi-list-select',
  templateUrl: './custom-field-multi-list-select.component.html',
  styleUrl: './custom-field-multi-list-select.component.scss',
})
export class CustomFieldMultiListSelectComponent implements OnInit, OnChanges {
  @Input() ppItem: TCustomFieldList;
  @Input() ppSelectedIds: string[];
  @Input() ppFilter = '';
  @Output() ppSelect = new EventEmitter();
  @Output() ppOnScroll = new EventEmitter();

  expanded: {
    [itemId: string]: boolean;
  } = {};
  EIconPath = EIconPath;
  visibleIds: string[] = [];
  halfSelected = false;

  ngOnInit(): void {
    this.visibleIds = this.getVisibleItemIds(this.ppItem.subList, this.ppFilter || '');
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }): void {
    if (changes.ppFilter) {
      this.visibleIds = this.getVisibleItemIds(this.ppItem.subList, this.ppFilter || '');
    }
  }

  onTableRendered(element: HTMLElement): void {
    if (element.scrollHeight === element.clientHeight) {
      this.ppOnScroll.emit(true);
    }
  }

  select(listItem: TCustomFieldList): void {
    this.ppSelect.emit(listItem);
  }

  toggleExpand(item: TCustomFieldList): void {
    this.expanded[item.id] = !this.expanded[item.id];
  }

  scrollHandler(event: Event): void {
    const element = event.target as HTMLElement;
    const scrolledToBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    this.ppOnScroll.emit(scrolledToBottom);
  }

  private getVisibleItemIds(list: TCustomFieldList[], keyword: string): string[] {
    const visibleIds: string[] = [];

    for (const item of list) {
      // Check if the current item's label contains the keyword
      const isMatch = item.label.indexOf(keyword) !== -1;

      // Search recursively in subList
      let childIds: string[] = [];
      if (item.subList) {
        childIds = this.getVisibleItemIds(item.subList, keyword);
      }

      // If this item or any child matches, add its ID to the result
      if (isMatch || childIds.length > 0) {
        visibleIds.push(item.id);
      }

      // Add all child IDs that match
      visibleIds.push(...childIds);
    }

    return visibleIds;
  }

  isSelected(id: string): boolean {
    return this.ppSelectedIds.includes(id);
  }

  isHalfSelected(item: TCustomFieldList): boolean {
    this.halfSelected =
      (item.subList.some((subItem) => this.ppSelectedIds.includes(subItem.id)) &&
        item.subList.some((subItem) => !this.ppSelectedIds.includes(subItem.id))) ||
      item.subList.some((subItem) => this.isHalfSelected(subItem));
    return this.halfSelected;
  }
}
