import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { EIntegrationStatus, TCustomField, TCustomFieldList } from '../../custom-fields.model';
import { TBulkCustomFields, TBulkCustomFieldsExtended } from '../bulk-custom-fields.model';
import { CustomFieldsService } from '../../custom-fields.service';
import { CustomFieldMultiListSelectDropdownComponent } from '../../custom-field-multi-list-select-dropdown/custom-field-multi-list-select-dropdown.component';
import {
  findMultiListItemById,
  generateMultiListValueFromSelectedIds,
  keepOnlyTopLevelSelected,
} from '../../custom-field-multi-list-select/custom-field-multi-list-select-utils';

@Component({
  selector: 'pp-bulk-field-multi-list',
  templateUrl: './bulk-field-multi-list.component.html',
  styleUrls: ['../bulk-custom-fields-modal.component.scss'],
})
export class BulkFieldMultiListComponent implements OnInit {
  @Input() ppCustomField: TCustomField;
  @Input() ppCustomFields: TBulkCustomFields<string>;
  @Input() ppFieldId: string;
  @Input() ppCustomFieldsExtended: TBulkCustomFieldsExtended<string>;
  ppFieldValue = '';

  @Output() ppCustomFieldsExtendedChange = new EventEmitter<TBulkCustomFieldsExtended>();
  @Output() ppCustomFieldsChange = new EventEmitter<TBulkCustomFields>();

  @ViewChild('pointFieldsList') pointFieldsListElement: ElementRef;

  EIconPath = EIconPath;
  EIntegrationStatus = EIntegrationStatus;
  volyIntegrationActive = '';
  updating = false;
  error = false;
  focused = false;
  dropdownVisible = false;

  constructor(
    private customFieldsService: CustomFieldsService,
    private dropdownService: DropdownService,
  ) {}

  ngOnInit(): void {
    this.volyIntegrationActive = this.ppCustomField.volyIntegrationActive;
  }

  clearCustomField(field: TCustomField): void {
    this.ppCustomFields[field.id] = undefined;
    this.ppCustomFieldsExtended[field.id] = {};

    this.ppCustomFieldsChange.emit(this.ppCustomFields);
    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
  }

  showList(): void {
    this.focused = true;

    const inputElement = this.pointFieldsListElement.nativeElement.getBoundingClientRect();

    this.dropdownService.setData({
      field: this.ppCustomField,
      selected: [],
    });

    this.dropdownService.showDropdown(
      `cbulkMultiList${this.ppFieldId}`,
      CustomFieldMultiListSelectDropdownComponent,
      {
        callback: (item) => {
          this.successCallback(item);
        },
        suppressScrollbar: true,
        width: `${Math.max(225, inputElement.width)}px`,
      },
    );

    this.dropdownVisible = true;
  }

  selectCustomField(selectedIds: string[]): void {
    const topLevelItems = keepOnlyTopLevelSelected(this.ppCustomField.subList, selectedIds);
    const sort = generateMultiListValueFromSelectedIds(this.ppCustomField.subList, selectedIds);

    const sortedVals = this.sortValues(sort);

    this.ppFieldValue = sortedVals;
    this.ppCustomFields[this.ppFieldId] = this.ppFieldValue;

    this.ppCustomFieldsExtended[this.ppFieldId] = {
      idOfChosenElements: topLevelItems,
      value: this.ppFieldValue,
      label: this.ppCustomField.label,
      type: this.ppCustomField.type,
    };
  }

  sortValues(vals: string): string {
    let unsortedVals = vals.split(', ');

    return unsortedVals.sort().join(', ');
  }

  private successCallback(itemIds: string[]) {
    if (itemIds) {
      this.selectCustomField(itemIds);
    }

    this.ppCustomFieldsChange.emit(this.ppCustomFields);
    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
  }
}
