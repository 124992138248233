import { Component, Input } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TShare } from 'src/app/project/modules/share/share.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EditAccountUserModalDataService } from '../../edit-account-user-modal-data.service';

@Component({
  selector: 'pp-account-user-modal-shares-list-bulk-share',
  templateUrl: './account-user-modal-shares-list-bulk-share.component.html',
  styleUrl: './account-user-modal-shares-list-bulk-share.component.scss',
})
export class AccountUserModalSharesListBulkShareComponent {
  @Input() ppShowBulkAccessibleTags: boolean;
  @Input() ppWorkspaceId: string;

  bulkShare: TShare;
  EUserRole = EUserRole;
  EIconPath = EIconPath;

  constructor(private editAccountUserModalDataService: EditAccountUserModalDataService) {
    this.setInitialData();
    this.checkIfSharesMatch();

    this.editAccountUserModalDataService.sharesBulkChange$.subscribe(() => {
      this.checkIfSharesMatch();
    });
  }

  updateShareRole(): void {
    this.editAccountUserModalDataService.bulkChangeRole(this.bulkShare);
  }

  updateShareTags(): void {
    this.editAccountUserModalDataService.bulkChangeTags(this.bulkShare);
  }

  updateShareAccessibleTags(): void {
    this.editAccountUserModalDataService.bulkChangeAccessibleTags(this.bulkShare);
  }

  updateShareActivity(): void {
    this.editAccountUserModalDataService.bulkChangeActivity(this.bulkShare);
  }

  updateShareExports(): void {
    this.editAccountUserModalDataService.bulkChangeExports(this.bulkShare);
  }

  updateShareComments(): void {
    this.editAccountUserModalDataService.bulkChangeComments(this.bulkShare);
  }

  setRole(shares: TShare[]): void {
    const roles = shares.map((share) => share.shareOption);
    const role = roles.every((r) => r === roles[0]) ? roles[0] : null;

    this.bulkShare = {
      ...this.bulkShare,
      shareOption: role,
    };
  }

  setTags(shares: TShare[]): void {
    const tags = shares.map((share) => share.advancedAccessLevels.tags.permission);
    const tag = tags.every((t) => isEqual(t, tags[0]))
      ? cloneDeep(tags[0])
      : {
          read: null,
          edit: null,
        };

    this.bulkShare.advancedAccessLevels.tags.permission = { ...tag };
  }

  setActivity(shares: TShare[]): void {
    const activities = shares.map((share) => share.advancedAccessLevels.timeline.permission);
    const activity = activities.every((a) => isEqual(a, activities[0]))
      ? cloneDeep(activities[0])
      : {
          read: null,
        };

    this.bulkShare.advancedAccessLevels.timeline.permission = { ...activity };
  }

  setExports(shares: TShare[]): void {
    const exports = shares.map((share) => share.advancedAccessLevels.exports.permission);
    const exportPermission = exports.every((e) => isEqual(e, exports[0]))
      ? cloneDeep(exports[0])
      : {
          read: null,
        };

    this.bulkShare.advancedAccessLevels.exports.permission = { ...exportPermission };
  }

  setComments(shares: TShare[]): void {
    const comments = shares.map((share) => share.advancedAccessLevels.timeline.comments.permission);
    const comment = comments.every((c) => isEqual(c, comments[0]))
      ? cloneDeep(comments[0])
      : {
          read: null,
          edit: null,
        };

    this.bulkShare.advancedAccessLevels.timeline.comments.permission = { ...comment };
  }

  setAvailableTags(shares: TShare[]): void {
    const tagLists = shares.map((share) => share.defectTags);
    const availableTags = tagLists.every((list) => isEqual(list, tagLists[0]))
      ? cloneDeep(tagLists[0])
      : [];

    this.bulkShare.defectTags = availableTags;
  }

  private checkIfSharesMatch(): void {
    const sharesMap = this.editAccountUserModalDataService.getSharesMap();
    let shares = this.editAccountUserModalDataService.getIndividualShares();

    if (shares.length === 0) {
      shares = Object.values(sharesMap);

      if (shares.length === 0) {
        return;
      }
    }

    this.setRole(shares);
    this.setTags(shares);
    this.setActivity(shares);
    this.setExports(shares);
    this.setComments(shares);
    this.setAvailableTags(shares);
  }

  private setInitialData(): void {
    this.bulkShare = {
      shareOption: null,
      tagLimited: false,
      defectTags: [],
      advancedAccessLevels: {
        tags: {
          permission: {
            read: null,
            edit: null,
          },
        },
        timeline: {
          comments: {
            permission: {
              read: null,
              edit: null,
            },
          },
          permission: {
            read: null,
          },
        },
        sitePlan: {
          permission: {
            read: null,
          },
        },
        exports: {
          permission: {
            read: null,
          },
        },
        customFields: [],
      },
    } as Partial<TShare> as TShare;
  }
}
