import { TCustomFieldList } from '../custom-fields.model';

export function toggleItemAndDescendants(
  list: TCustomFieldList[],
  targetId: string,
  selected: boolean,
  selectedIds: string[],
): string[] {
  for (const item of list) {
    if (item.id === targetId) {
      // Select this item and all its descendants
      selectedIds = markSelected(item, selected, selectedIds);
    }

    // If subList exists, search recursively
    if (item.subList) {
      selectedIds = toggleItemAndDescendants(item.subList, targetId, selected, selectedIds);
    }
  }

  return selectedIds;
}

// Helper function to mark an item and its descendants as selected
function markSelected(item: TCustomFieldList, selected: boolean, selectedIds: string[]): string[] {
  if (!selected) {
    selectedIds = selectedIds.filter((id) => id !== item.id);
  } else {
    selectedIds.push(item.id);
  }

  if (item.subList) {
    for (const child of item.subList) {
      selectedIds = markSelected(child, selected, selectedIds);
    }
  }

  return selectedIds;
}

export function deselectIfChildrenUnselected(
  list: TCustomFieldList[],
  selectedIds: string[],
): string[] {
  for (const item of list) {
    if (item.subList) {
      // Recursively update the subList
      const updatedSublistSelectedIds = deselectIfChildrenUnselected(item.subList, selectedIds);

      // Check if all children are in the updated selected list
      const allChildrenSelected = item.subList.every((child) =>
        updatedSublistSelectedIds.includes(child.id),
      );

      // Deselect the current item if not all children are selected
      if (!allChildrenSelected) {
        selectedIds = selectedIds.filter((id) => id !== item.id);
      }
    }
  }

  return selectedIds; // Return the updated selected IDs
}

function selectIfAllChildrenSelected(list: TCustomFieldList[], selectedIds: string[]): boolean {
  let allSelected = true;

  for (const item of list) {
    // If the item has a subList, check its children recursively
    let areChildrenSelected = true;
    if (item.subList) {
      areChildrenSelected = selectIfAllChildrenSelected(item.subList, selectedIds);
    }

    // If the current item is not selected or its children are not all selected, set allSelected to false
    if (!selectedIds.includes(item.id) || !areChildrenSelected) {
      allSelected = false;
    }
  }

  // If all children (or the current level) are selected, mark this level as selected
  return allSelected;
}

// Function to apply the logic to the entire list
export function updateSelectionsBasedOnChildren(
  list: TCustomFieldList[],
  selectedIds: string[],
): string[] {
  for (const item of list) {
    if (item.subList?.length > 0) {
      const areAllChildrenSelected = selectIfAllChildrenSelected(item.subList, selectedIds);
      if (areAllChildrenSelected && !selectedIds.includes(item.id)) {
        selectedIds.push(item.id);
      }
    }
  }

  return selectedIds;
}

// Function to deselect descendants if the parent is selected
export function keepOnlyTopLevelSelected(
  list: TCustomFieldList[],
  selectedIds: string[],
): string[] {
  for (const item of list) {
    if (selectedIds.includes(item.id)) {
      // If the item is selected, deselect all its descendants
      selectedIds = deselectDescendants(selectedIds, item.subList);
    } else if (item.subList) {
      // Recursively apply the function to the sublist
      selectedIds = keepOnlyTopLevelSelected(item.subList, selectedIds);
    }
  }

  return selectedIds;
}

// Helper function to deselect all descendants
function deselectDescendants(selectedIds: string[], subList?: TCustomFieldList[]): string[] {
  if (!subList) return selectedIds;

  for (const child of subList) {
    selectedIds = selectedIds.filter((id) => id !== child.id);

    if (child.subList) {
      selectedIds = deselectDescendants(selectedIds, child.subList);
    }
  }

  return selectedIds;
}

// Function to ensure children are selected if the parent is selected
export function propagateSelection(list: TCustomFieldList[], selectedIds: string[]): string[] {
  for (const item of list) {
    if (selectedIds.includes(item.id)) {
      // If the item is selected, mark all its children as selected
      markAllAsSelected(item, selectedIds);
    }

    // Recursively process subList if it exists
    if (item.subList) {
      propagateSelection(item.subList, selectedIds);
    }
  }

  return selectedIds;
}

// Helper function to mark an item and all its descendants as selected
function markAllAsSelected(item: TCustomFieldList, selectedIds: string[]): void {
  if (!selectedIds.includes(item.id)) {
    selectedIds.push(item.id);
  }

  if (item.subList) {
    for (const child of item.subList) {
      markAllAsSelected(child, selectedIds); // Recursively mark all children
    }
  }
}

//Function to provide the value of a multi list we wish to display correctly,i.e. adding the (all) suffix
export function generateMultiListValueFromSelectedIds(
  list: TCustomFieldList[],
  selectedIds: string[],
): string {
  const resultLabels: string[] = [];

  if (!selectedIds) return;
  for (const item of list) {
    if (selectedIds.includes(item.id)) {
      resultLabels.push(item.subList.length > 0 ? item.label + ' (all)' : item.label);
    } else if (item.subList) {
      const subListResult = generateMultiListValueFromSelectedIds(item.subList, selectedIds);

      if (subListResult) {
        resultLabels.push(subListResult);
      }
    }
  }
  return resultLabels.join(', ');
}

export function findMultiListItemById(
  list: TCustomFieldList[],
  targetId: string,
): { label: string; id: string } | null {
  for (const item of list) {
    if (item.id === targetId) {
      return { label: item.label, id: item.id };
    }
    if (item.subList) {
      const found = findMultiListItemById(item.subList, targetId);
      if (found) {
        return found;
      }
    }
  }
  return null; // Return null if the item is not found
}
