import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { CustomFieldMultiListSelectDropdownComponent } from 'src/app/project/modules/custom-fields/custom-field-multi-list-select-dropdown/custom-field-multi-list-select-dropdown.component';
import {
  generateMultiListValueFromSelectedIds,
  keepOnlyTopLevelSelected,
} from 'src/app/project/modules/custom-fields/custom-field-multi-list-select/custom-field-multi-list-select-utils';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { TCustomFieldFilter, TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-filter-field-multi-list',
  templateUrl: './filter-field-multi-list.component.html',
  styleUrls: ['./filter-field-multi-list.component.scss'],
})
export class FilterFieldMultiListComponent {
  @Input() ppFilters: TFilters;
  @Input() ppSelectedCF: string;
  @Input() ppField: TCustomFieldFilter;

  @Output() ppApply = new EventEmitter();

  @Output() ppSelectCf = new EventEmitter();

  EIconPath = EIconPath;

  constructor(
    private customFieldsService: CustomFieldsService,
    private dropdownService: DropdownService,
  ) {}

  showList(buttonId: string, filterField: TCustomFieldFilter): void {
    const customFields = this.customFieldsService.getCustomFields();
    const index = this.ppFilters.customFields.findIndex(
      (customField) =>
        customField.id.toString() === filterField.id.toString() &&
        filterField.workspaceId === customField.workspaceId,
    );
    this.selectCf(filterField.id);
    const field = customFields[this.ppFilters.customFields[index].workspaceId][filterField.id];

    this.dropdownService.setData({
      field,
      selected: this.ppFilters.customFields[index].multiListFilter?.selectedIds || [],
    });

    this.dropdownService.showDropdown(buttonId, CustomFieldMultiListSelectDropdownComponent, {
      suppressScrollbar: true,
      callback: (item) => {
        if (item) {
          const topLevelItems = keepOnlyTopLevelSelected(field.subList, item);
          const multiListValue = generateMultiListValueFromSelectedIds(field.subList, item);

          const sortedVals = this.sortValues(multiListValue);

          if (!this.ppFilters.customFields[index].multiListFilter) {
            this.ppFilters.customFields[index].multiListFilter = {
              value: sortedVals,
              combineValues: false,
              selectedIds: topLevelItems,
            };
          } else {
            this.ppFilters.customFields[index].multiListFilter = {
              value: sortedVals,
              combineValues: this.ppField.multiListFilter.combineValues,
              selectedIds: topLevelItems,
            };
          }
        } else {
          this.ppFilters.customFields[index].multiListFilter.selectedIds = null;
          this.ppFilters.customFields[index].multiListFilter.value = null;
        }

        this.ppFilters.customFields[index].excluded = false;

        this.applyFilters();
      },
      onClose: () => {},
    });
  }

  sortValues(vals: string): string {
    let unsortedVals = vals.split(', ');

    return unsortedVals.sort().join(', ');
  }

  toggleCombineMultiList(): void {
    if (this.ppField.multiListFilter) {
      this.ppField.multiListFilter.combineValues = !this.ppField.multiListFilter.combineValues;
    } else {
      this.ppField.multiListFilter = {
        value: null,
        selectedIds: null,
        combineValues: !this.ppField.multiListFilter?.combineValues,
      };
    }

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }

  selectCf(selectedCf: string | number): void {
    this.ppSelectCf.emit(selectedCf);
  }
}
