export enum EAdvancedFilterType {
  TEXT = 'TEXT',
  RICH_TEXT = 'RICH_TEXT',
  LIST = 'LIST',
  COST = 'COST',
  NUMBER = 'NUMBER',
  SEQUENCE_NUMBER = 'SEQUENCE_NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  DURATION = 'DURATION',
  FORMULA = 'FORMULA',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  TIMELINE = 'TIMELINE',
  LOCATION = 'LOCATION',
  REMINDER = 'REMINDER',
  ASSIGNEE = 'ASSIGNEE',
  CREATED_BY = 'CREATED_BY',
  PRIORITY = 'PRIORITY',
  STATUS = 'STATUS',
  TAG = 'TAG',
  DATE_UPDATED = 'DATE_UPDATED',
  DATE_CREATED = 'DATE_CREATED',
  FLAGGED = 'FLAGGED',
  MULTI_LIST = 'MULTI_LIST',
}
