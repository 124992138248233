<div
  #pointFieldsList
  id="{{ 'filtercfMultiList' + ppField.id.toString() }}"
  class="select select--w100"
  (click)="showList('filtercfMultiList' + ppField.id.toString(), ppField)"
>
  <span *ngIf="ppField.multiListFilter?.value">{{ ppField.multiListFilter.value }}</span>
  <span class="multiListFilter__selectedItemsPlaceholder" *ngIf="!ppField.multiListFilter?.value">{{
    'select...' | ppTranslate
  }}</span>
</div>

<div class="multiListFilter__checkbox">
  <pp-checkbox
    [ppChecked]="ppField.multiListFilter?.combineValues"
    (ppOnValueChange)="toggleCombineMultiList()"
  >
    <p class="checkbox__label">
      {{ 'combine_selected_items' | ppTranslate }}

      <i
        ppTooltip
        class="fa fa-info-circle"
        aria-hidden="true"
        [ppMobileTooltip]="true"
        [ppTitle]="'combine_selected_items_description' | ppTranslate"
      ></i>
    </p>
  </pp-checkbox>
</div>
