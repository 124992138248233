<div class="point__title">
  <span class="point__title-wrapper">
    <input
      #pointTitle
      preventPointSwitching
      id="titleField"
      class="point__title-input point__title-tooltip"
      [class.point__title-input--failed]="error"
      [class.point__title-input--disabled]="offline || !ppCanEdit"
      (focus)="onFocus()"
      (blur)="onBlur()"
      ppTooltip
      [ppTrimTooltip]="true"
      ppTitle="{{ ppTitle }}"
      type="text"
      name="title"
      [(ngModel)]="ppTitle"
      (change)="updateTitle()"
      placeholder="Enter point title..."
      [readonly]="offline || !ppCanEdit"
      (keydown.enter)="blurInput($event)"
      (ppOnRendered)="onTitleRendered()"
      data-test="pointTitle"
    />

    <pp-save-indicator [ppUpdating]="processing" [ppShowClearBtn]="false"></pp-save-indicator>
  </span>
</div>
