import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterMultiList,
  TAdvancedFilterMultiListValue,
  TAdvancedFilterSingle,
  TFilterMultiListOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedMultiListOptions } from './advanced-filter-multi-list-utils';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';

@Component({
  selector: 'pp-advanced-filter-multi-list',
  templateUrl: './advanced-filter-multi-list.component.html',
  styleUrls: ['./advanced-filter-multi-list.component.scss'],
})
export class AdvancedFilterMultiListComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Input() ppWorkspaceId: string;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  advancedFilterMultiListOptions = getAdvancedMultiListOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;
  value: TAdvancedFilterMultiListValue;
  option: TFilterMultiListOptions;
  filter: TAdvancedFilterMultiList;
  unit: string;
  field: TCustomField;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterMultiList;

    this.value = this.filter.value;
    this.option = this.filter.option;
    this.field = GET_CUSTOM_FIELDS()[this.filter.id];
  }

  changeFieldCondition(newCondition: TFilterMultiListOptions): void {
    this.filter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilter = this.filter;
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: TAdvancedFilterMultiListValue): void {
    this.filter.value = value;
    this.applyChanges();
  }
}
