<div class="editAccountUserCustomFields__wrapper">
  <div class="editAccountUserCustomFields__fields">
    <header class="editAccountUserCustomFields__fieldsHeader">
      {{ 'custom_fields' | ppTranslate }}
    </header>

    <section class="editAccountUserCustomFields__fieldsTable">
      <ng-container
        *ngFor="
          let customField of share.advancedAccessLevels.customFields;
          let even = even;
          let index = index
        "
      >
        <pp-account-user-modal-custom-field
          *ngIf="checkCFVisibility(customField)"
          [ppCustomField]="customField"
          (ppCustomFieldChange)="updateCustomField(index, $event)"
          [ppWorkspaceId]="ppWorkspaceId"
          [ppShareOption]="share.shareOption"
          [ppEven]="even"
        ></pp-account-user-modal-custom-field>
      </ng-container>
    </section>
  </div>
</div>
