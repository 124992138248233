import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TShare } from 'src/app/project/modules/share/share.model';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TAccount } from '../../../account.model';
import { AddUsersIssuesService } from '../../add-users-modal/add-users-issues.service';
import { EditAccountUserModalDataService } from '../edit-account-user-modal-data.service';

@Component({
  selector: 'pp-account-user-modal-shares-list',
  templateUrl: './account-user-modal-shares-list.component.html',
  styleUrls: ['./account-user-modal-shares-list.component.scss'],
})
export class AccountUserModalSharesListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppAccount: TAccount;
  @Input() ppSelectedWorkspaceIds: string[];
  @Input() ppEmailsReadOnly: boolean;
  @Input() ppShowBulkAccessibleTags = false;
  @Input() ppSelectedShares: TShare[];
  @Output() ppSelectedSharesChange = new EventEmitter<TShare[]>();

  @Output() ppGoBack = new EventEmitter<void>();
  @Output() ppShowCustomFieldsTable = new EventEmitter<string>();
  @Output() ppShowIndividualCustomFieldsTable = new EventEmitter<TShare>();

  @Input() ppShowingError: boolean;
  @Input() ppShowInfoBox: boolean;

  private readonly destroy$ = new Subject<void>();

  private workspacesWithErrors: string[] = [];

  EIconPath = EIconPath;
  emailList: string[] = [];
  private selectedWorkspaceIds: string[] = [];
  editingUsers = false;
  warningMessages: string[] = [];
  selectedWorkspaces: {
    workspaceId: string;
    hasError: boolean;
  }[] = [];

  constructor(
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private addUsersIssuesService: AddUsersIssuesService,
  ) {
    this.subscribeToErrorChange();
    this.subscribeToWarningChange();
  }

  ngOnChanges(): void {
    this.trySetEditingUsers();
  }

  ngOnInit(): void {
    this.emailList = this.editAccountUserModalDataService.getEmailList();
    this.selectedWorkspaceIds = this.editAccountUserModalDataService.getSelectedWorkspaces();
    this.setSelectedWorkspaces();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goBack(): void {
    this.ppGoBack.emit();
  }

  showCustomFieldsTable(workspaceId: string): void {
    this.ppShowCustomFieldsTable.emit(workspaceId);
  }

  showIndividualCustomFieldsTable(share: TShare): void {
    this.ppShowIndividualCustomFieldsTable.emit(share);
  }

  setEmailList(emailList: string[]): void {
    this.editAccountUserModalDataService.setEmailList(emailList);
  }

  updateShare(share: TShare, index: number): void {
    this.ppSelectedShares[index] = share;

    this.ppSelectedSharesChange.emit(this.ppSelectedShares);
  }

  private subscribeToErrorChange() {
    this.addUsersIssuesService.errorChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleErrorChange();
    });
  }

  private handleErrorChange(): void {
    this.setWorkspacesWithErrors();
    this.setSelectedWorkspaces();
  }

  private setWorkspacesWithErrors(): void {
    this.workspacesWithErrors = this.addUsersIssuesService.getWorkspacesWithErrors();
  }

  private setSelectedWorkspaces(): void {
    this.selectedWorkspaces = this.selectedWorkspaceIds.map((workspaceId) => {
      return {
        workspaceId,
        hasError: this.workspacesWithErrors.includes(workspaceId),
      };
    });
  }

  private subscribeToWarningChange() {
    this.addUsersIssuesService.warningChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setWarningMessages();
    });
  }

  private setWarningMessages(): void {
    this.warningMessages = this.addUsersIssuesService.getWarningMessages();
  }

  private trySetEditingUsers(): void {
    if (this.ppSelectedWorkspaceIds?.length) {
      this.editingUsers = true;
    }
  }
}
