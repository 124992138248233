import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedFiltersDropdownComponent } from './advanced-filters-dropdown/advanced-filters-dropdown.component';
import { AdvancedFiltersRowComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filters-row.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { SelectModule } from '../../components/input/select/select.module';
import { FormsModule } from '@angular/forms';
import { AdvancedFilterOptionSelectComponent } from './advanced-filters-dropdown/input-fields/advanced-filter-option-select/advanced-filter-option-select.component';
import { AdvancedFilterFieldSelectComponent } from './advanced-filters-dropdown/input-fields/advanced-filter-field-select/advanced-filter-field-select.component';
import { SelectMultipleModule } from '../../components/input/select-multiple/select-multiple.module';
import { AdvancedFilterConditionComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-condition/advanced-filter-condition.component';
import { AdvancedFilterTextComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-text/advanced-filter-text.component';
import { AdvancedFilterTextInputComponent } from './advanced-filters-dropdown/input-fields/advanced-filter-text-input/advanced-filter-text-input.component';
import { AdvancedFilterPriorityComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-priority/advanced-filter-priority.component';
import { AdvancedFilterPercentageComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-percentage/advanced-filter-percentage.component';
import { AdvancedFilterNumberInputComponent } from './advanced-filters-dropdown/input-fields/advanced-filter-number-input/advanced-filter-number-input.component';
import { AdvancedFilterCheckboxComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-checkbox/advanced-filter-checkbox.component';
import { AdvancedFilterDurationComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-duration/advanced-filter-duration.component';
import { TimeInputModule } from '../../components/input/time-input/time-input.module';
import { IconModule } from '../../components/icon/icon-module';
import { AdvancedFilterTagComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-tag/advanced-filter-tag.component';
import { TagsModule } from '../tags/tags.module';
import { AdvancedFilterStatusComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-status/advanced-filter-status.component';
import { AdvancedFilterDateComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-date/advanced-filter-date.component';
import { DatepickerModule } from '../../components/input/datepicker/datepicker.module';
import { AdvancedFilterTimelineComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-timeline/advanced-filter-timeline.component';
import { UserPickerModule } from '../../components/user-picker/user-picker.module';
import { AdvancedFilterUserComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-users/advanced-filter-users.component';
import { AdvancedFilterLocationComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-location/advanced-filter-location.component';
import { AdvancedFilterNumberComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-number/advanced-filter-number.component';
import { AdvancedFilterListComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-list/advanced-filter-list.component';
import { AdvancedFilterListInputComponent } from './advanced-filters-dropdown/input-fields/advanced-filter-list-input/advanced-filter-list-input.component';
import { AdvancedFilterListInputDropdownComponent } from './advanced-filters-dropdown/input-fields/advanced-filter-list-input/advanced-filter-list-input-dropdown/advanced-filter-list-input-dropdown.component';
import { CustomFieldsModule } from '../custom-fields/custom-fields.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { FilterFieldNumberInputModule } from '../../components/filter-field-numbers-input/filter-field-number-input.module';
import { AdvancedFilterFlagComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-flag/advanced-filter-flag.component';
import { AdvancedFilterFormulaComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-formula/advanced-filter-formula.component';
import { AdvancedFilterMultiListComponent } from './advanced-filters-dropdown/advanced-filters-row/advanced-filter-types/advanced-filter-mulit-list/advanced-filter-multi-list.component';
import { AdvancedFilterMultiListInputComponent } from './advanced-filters-dropdown/input-fields/advanced-filter-multi-list-input/advanced-filter-multi-list-input.component';

@NgModule({
  declarations: [
    AdvancedFiltersDropdownComponent,
    AdvancedFiltersRowComponent,
    AdvancedFilterPriorityComponent,
    AdvancedFilterOptionSelectComponent,
    AdvancedFilterFieldSelectComponent,
    AdvancedFilterConditionComponent,
    AdvancedFilterTextComponent,
    AdvancedFilterTextInputComponent,
    AdvancedFilterPercentageComponent,
    AdvancedFilterNumberInputComponent,
    AdvancedFilterCheckboxComponent,
    AdvancedFilterDurationComponent,
    AdvancedFilterTagComponent,
    AdvancedFilterStatusComponent,
    AdvancedFilterDateComponent,
    AdvancedFilterTimelineComponent,
    AdvancedFilterUserComponent,
    AdvancedFilterLocationComponent,
    AdvancedFilterNumberComponent,
    AdvancedFilterFormulaComponent,
    AdvancedFilterListComponent,
    AdvancedFilterListInputComponent,
    AdvancedFilterMultiListInputComponent,
    AdvancedFilterListInputDropdownComponent,
    AdvancedFilterFlagComponent,
    AdvancedFilterMultiListComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    SelectModule,
    FormsModule,
    SelectMultipleModule,
    TimeInputModule,
    IconModule,
    TagsModule,
    DatepickerModule,
    UserPickerModule,
    CustomFieldsModule,
    SearchBoxModule,
    TooltipsModule,
    FilterFieldNumberInputModule,
  ],
  exports: [AdvancedFiltersDropdownComponent],
})
export class FiltersAdvancedModule {}
