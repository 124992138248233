import { Injectable } from '@angular/core';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { FilterKeywordService } from '../../filters/filter-logic/filter-keyword.service';
import { TFilters } from '../../filters/site-filter.model';
import { TPoint } from '../../points/points.model';
import { AdvancedFilterService } from '../advanced-filter.service';
import { EAdvancedFilterType } from '../models/advanced-filter-type.enum';
import {
  TAdvancedFilter,
  TAdvancedFilterSingle,
  TAdvancedFilterTimeline,
} from '../models/advanced-filter.model';
import { filterAssigneeAdvanced } from './utils/filter-assignee-advanced';
import { filterCheckboxAdvanced } from './utils/filter-checkbox-advanced';
import { filterCreatedByAdvanced } from './utils/filter-created-by-advanced';
import { filterDateAdvanced } from './utils/filter-date-advanced';
import { filterDurationAdvanced } from './utils/filter-duration-advanced';
import { filterFlagAdvanced } from './utils/filter-flag-advanced';
import { filterListAdvanced } from './utils/filter-list-advanced';
import { filterLocationAdvanced } from './utils/filter-location-advanced';
import { filterNumberAdvanced } from './utils/filter-number-advanced';
import { filterPercentageAdvanced } from './utils/filter-percentage-advanced';
import { filterPriorityAdvanced } from './utils/filter-priority-advanced';
import { filterRichTextAdvanced } from './utils/filter-rich-text-advanced';
import { filterStatusAdvanced } from './utils/filter-status-advanced';
import { filterTagAdvanced } from './utils/filter-tag-advanced';
import { filterTextAdvanced } from './utils/filter-text-advanced';
import { filterTimelineAdvanced } from './utils/filter-timeline-advanced';
import { filterFormulaAdvanced } from './utils/filter-formula-advanced';
import { filterMultiListAdvanced } from './utils/filter-multi-list-advanced';

type TFilterFunctionParam = (point: TPoint, filter: TAdvancedFilterSingle) => boolean;
type TFilterFunction = {
  filter: TFilterFunctionParam;
  body: TAdvancedFilterSingle;
};
@Injectable({
  providedIn: 'root',
})
export class CheckAdvancedPointService {
  constructor(
    private advancedFilterService: AdvancedFilterService,
    private filterKeywordService: FilterKeywordService,
  ) {}

  checkPoint(point: TPoint, workspaceId: string, filters: TFilters): boolean {
    let match = true;
    const workspaceFilters = this.advancedFilterService.getAdvancedFilter(workspaceId);
    const listOfFilters = this.getListOfFilters(workspaceFilters);

    if (point.sequenceNumber === NEW_POINT_ID) {
      return false;
    }

    if (!this.filterKeywordService.checkKeyword(point, filters)) {
      return false;
    }

    switch (workspaceFilters.logic) {
      case 'OR':
        match = this.filterPointsOR(point, listOfFilters);

        break;
      case 'AND':
      default:
        match = this.filterPointsAND(point, listOfFilters);
        break;
    }

    return match;
  }

  private filterPointsOR(point: TPoint, listOfFilters: TFilterFunction[]): boolean {
    for (let i = 0; i < listOfFilters.length; i++) {
      const filter = listOfFilters[i];

      if (filter.filter(point, filter.body)) {
        return true;
      }
    }

    return false;
  }

  private filterPointsAND(point: TPoint, listOfFilters: TFilterFunction[]): boolean {
    for (let i = 0; i < listOfFilters.length; i++) {
      const filter = listOfFilters[i];

      if (!filter.filter(point, filter.body)) {
        return false;
      }
    }
    return true;
  }

  getListOfFilters(workspaceFilters: TAdvancedFilter): TFilterFunction[] {
    const listOfFilters: TFilterFunction[] = [];

    workspaceFilters.filters.forEach((filter) => {
      if (filter.option || (filter as TAdvancedFilterTimeline).date) {
        switch (filter.type) {
          case EAdvancedFilterType.PRIORITY:
            listOfFilters.push({
              filter: filterPriorityAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.TEXT:
            listOfFilters.push({
              filter: filterTextAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.PERCENTAGE:
            listOfFilters.push({
              filter: filterPercentageAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.FORMULA:
            listOfFilters.push({
              filter: filterFormulaAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.RICH_TEXT:
            listOfFilters.push({
              filter: filterRichTextAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.CHECKBOX:
            listOfFilters.push({
              filter: filterCheckboxAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.DURATION:
            listOfFilters.push({
              filter: filterDurationAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.STATUS:
            listOfFilters.push({
              filter: filterStatusAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.TAG:
            listOfFilters.push({
              filter: filterTagAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.REMINDER:
          case EAdvancedFilterType.DATE:
          case EAdvancedFilterType.DATE_CREATED:
          case EAdvancedFilterType.DATE_UPDATED:
            listOfFilters.push({
              filter: filterDateAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.TIMELINE:
            listOfFilters.push({
              filter: filterTimelineAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.CREATED_BY:
            listOfFilters.push({
              filter: filterCreatedByAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.FLAGGED:
            listOfFilters.push({
              filter: filterFlagAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.ASSIGNEE:
            listOfFilters.push({
              filter: filterAssigneeAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.LOCATION:
            listOfFilters.push({
              filter: filterLocationAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.COST:
          case EAdvancedFilterType.SEQUENCE_NUMBER:
          case EAdvancedFilterType.NUMBER:
            listOfFilters.push({
              filter: filterNumberAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.LIST:
            listOfFilters.push({
              filter: filterListAdvanced,
              body: filter,
            });
            break;
          case EAdvancedFilterType.MULTI_LIST:
            listOfFilters.push({
              filter: filterMultiListAdvanced,
              body: filter,
            });
            break;

          default:
            break;
        }
      }
    });
    return listOfFilters;
  }
}
