import { TCustomFieldList } from '../../custom-fields/custom-fields.model';

export function checkMultilistForMatches(
  list: TCustomFieldList[],
  selectedIds: string[],
): string[] {
  let resultLabels: string[] = [];

  if (!selectedIds || list.length === 0) return;

  for (const item of list) {
    if (selectedIds.includes(item.id)) {
      resultLabels.push(item.subList.length > 0 ? item.label + ' (all)' : item.label);

      if (item.subList) resultLabels = [...resultLabels, ...addValuesFromSublist(item.subList)];
    } else if (item.subList) {
      const subListResult = checkMultilistForMatches(item.subList, selectedIds);
      if (subListResult) {
        resultLabels = [...resultLabels, ...subListResult];
      }
    }
  }
  return resultLabels;
}

function addValuesFromSublist(list: TCustomFieldList[]): string[] {
  let labels = [];
  for (const item of list) {
    labels.push(item.subList.length > 0 ? item.label + ' (all)' : item.label);
    if (item.subList) labels = [...labels, ...addValuesFromSublist(item.subList)];
  }

  return [...labels];
}
