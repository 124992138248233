import { TPointCustomField } from '@project/view-models';
import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { transformTimeCF } from '../../custom-fields/custom-field-time/custom-field-time-transformer';
import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { GET_PREFERENCES } from '../../preferences/preferences.store';
import { getCostExportValue } from './get-cost-export-value';
import { getNumbersExportValue } from './get-numbers-export-value';
import { getPercentageExportValue } from './get-percentage-export-value';

export function formatCustomFieldValue(
  pointCustomField: TPointCustomField,
  exportType: string,
): string {
  if (pointCustomField.value) {
    const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

    switch (customField.type) {
      case ECustomFieldType.LIST:
      case ECustomFieldType.TEXT:
      case ECustomFieldType.MULTI_LIST:
        return `${pointCustomField.value}`;
      case ECustomFieldType.NUMBERS:
        return getNumbersExportValue(exportType, pointCustomField);
      case ECustomFieldType.COST:
        return getCostExportValue(exportType, pointCustomField);
      case ECustomFieldType.TIME:
        return transformTimeCF(pointCustomField.value, customField.showHoursOnly);
      case ECustomFieldType.PERCENTAGE:
        return getPercentageExportValue(exportType, pointCustomField.value);
      case ECustomFieldType.CHECKBOX:
        return getCheckboxExportValue(pointCustomField);
      case ECustomFieldType.RICHTEXT:
        return getRichTextExportValue(pointCustomField);
      case ECustomFieldType.DATE:
        return getDateExportValue(pointCustomField);
      case ECustomFieldType.FORMULA: {
        switch (customField.outputType) {
          case ECustomFieldType.NUMBERS:
            return getNumbersExportValue(exportType, pointCustomField);
          case ECustomFieldType.COST:
            return getCostExportValue(exportType, pointCustomField);
          case ECustomFieldType.PERCENTAGE:
            return getPercentageExportValue(exportType, pointCustomField.value);
          default:
            return '';
        }
      }
    }
  }

  return '';
}
function getRichTextExportValue(customField: TPointCustomField): string {
  return `${generatePlainText(decodeBase64Text(customField.value)).trim()}`;
}

function getDateExportValue(customField: TPointCustomField): string {
  const preferences = GET_PREFERENCES();
  let dateValue = customField.value;

  return transformDate({
    value: dateValue,
    inputHourFormat: '',
    format: preferences.dateFormat,
    localTime: false,
  });
}

function getCheckboxExportValue(customField: TPointCustomField): string {
  if (customField.value === 'true') {
    return 'Yes';
  } else {
    return 'No';
  }
}
