<div #list (ppOnRendered)="onTableRendered($event)" (scroll)="scrollHandler($event)">
  <div *ngIf="ppItem.subList?.length === 0 && ppFilter !== ''" (click)="$event.stopPropagation()">
    <p class="listCF__not-found-message">
      {{ 'no_search_result' | ppTranslate }}
    </p>
  </div>

  <div class="checkboxContainer">
    <div class="listCFs_wrapper">
      <ng-container *ngFor="let item of ppItem.subList">
        <div
          class="listCF__list-item"
          *ngIf="item.label && visibleIds.includes(item.id)"
          (click)="select(item); $event.stopPropagation()"
        >
          <button
            (click)="toggleExpand(item); $event.stopPropagation()"
            class="listCF__list__chevronWrapper"
          >
            <pp-icon
              *ngIf="item.subList?.length > 0"
              [ppSrc]="
                expanded[item.id]
                  ? EIconPath.ICON_NAV_ARROW_DOWN_18
                  : EIconPath.ICON_NAV_ARROW_RIGHT_18
              "
              ppClass="setSize18"
              ppColor="grey-700"
            ></pp-icon>
          </button>

          <pp-checkbox
            [ppChecked]="isSelected(item.id)"
            [ppHalfChecked]="isHalfSelected(item)"
            ppType="solid"
            ppSize="medium"
          ></pp-checkbox>
          <label>
            {{ item.label }}
          </label>
          <p class="listCF__sublist-count" *ngIf="!expanded[item.id] && item.subList?.length > 0">
            ({{ item.subList?.length }})
          </p>
        </div>

        <div class="listCF__sublist-wrapper" *ngIf="item.subList?.length > 0 && expanded[item.id]">
          <pp-custom-field-multi-list-select
            class="listCF__sublist"
            [ppItem]="item"
            [ppFilter]="ppFilter"
            [ppSelectedIds]="ppSelectedIds"
            (ppSelect)="select($event)"
          ></pp-custom-field-multi-list-select>
        </div>
      </ng-container>
    </div>
  </div>
</div>
