<!-- Added multilist items -->
<div *ngIf="added.length > 0" class="multiSelect__activityRow">
  <span>
    <img [src]="EIconPath.CUSTOM_FIELD_MULTI_LIST_ACTIVITY" />
  </span>

  <div class="multiSelect__activityDescription">
    <span> {{ 'added' | ppTranslate }} </span>

    <span class="multiSelect__highlight" *ngFor="let value of added; let last = last">
      {{ value }} <span *ngIf="!last">, </span></span
    >

    <span>
      {{ 'in' | ppTranslate }}
      <span class="multiSelect__highlight">{{ ppChange.label }}</span></span
    >
  </div>
</div>

<!-- Removed multi list -->
<div *ngIf="removed.length > 0" class="multiSelect__activityRow">
  <span>
    <img [src]="EIconPath.CUSTOM_FIELD_MULTI_LIST_ACTIVITY" />
  </span>

  <div class="multiSelect__activityDescription">
    <span> {{ 'removed' | ppTranslate }} </span>

    <span class="multiSelect__highlight" *ngFor="let value of removed; let last = last">
      {{ value }} <span *ngIf="!last">, </span>
    </span>

    <span>
      {{ 'in' | ppTranslate }}
      <span class="multiSelect__highlight">{{ ppChange.label }}</span></span
    >
  </div>
</div>
