import { TFilters } from '../site-filter.model';

export function checkNumberOfAppliedFilters(filters: TFilters): number {
  let numberOfFilters = 0;

  if (filters.sitesDefault === false) {
    numberOfFilters++;
  }
  if (!filters.priorityDefault) {
    numberOfFilters++;
  }
  if (!filters.statusDefault) {
    numberOfFilters++;
  }
  if (filters.assignees?.appliedValue.length) {
    numberOfFilters++;
  }
  if (filters.createdBy?.appliedValue.length) {
    numberOfFilters++;
  }
  if (filters.tags?.appliedValue.length) {
    numberOfFilters++;
  }
  if (filters.excludedTags?.appliedValue.length) {
    numberOfFilters++;
  }
  if (filters.date?.created.appliedValues.startDate) {
    numberOfFilters++;
  }
  if (filters.date?.created.appliedValues.endDate) {
    numberOfFilters++;
  }
  if (filters.date?.updated.appliedValues.startDate) {
    numberOfFilters++;
  }
  if (filters.date?.updated.appliedValues.endDate) {
    numberOfFilters++;
  }
  if (!filters.showPointsWithoutLocation) {
    numberOfFilters++;
  }
  if (!filters.showPointsWithLocation) {
    numberOfFilters++;
  }
  if (!filters.showUnflaggedPoints) {
    numberOfFilters++;
  }
  if (!filters.showFlaggedPoints) {
    numberOfFilters++;
  }
  if (filters.reminders?.startDate || filters.reminders?.endDate) {
    numberOfFilters++;
  }

  filters.customFields.forEach((field) => {
    if (field.excluded) {
      numberOfFilters++;
    } else if (field.appliedValues) {
      if (
        field.appliedValues.startDate ||
        field.appliedValues.endDate ||
        field.appliedValues.min ||
        field.appliedValues.max ||
        field.appliedValues.text ||
        field.multiListFilter?.value ||
        field.value === true
      ) {
        numberOfFilters++;
      }
    }
  });

  return numberOfFilters;
}
