import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { getValues } from './get-values';

export const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export function sortText(
  _a: TPoint | string,
  _b: TPoint | string,
  sortColumns: Partial<TColumn>[],
  index: number,
  { property = null }: { property?: string } = {},
): number {
  let values: any = {
    a: _a,
    b: _b,
  };

  if (property) {
    values = getValues(_a, _b, property);
  }

  if (values.a === '' && values.b !== '') {
    return 1;
  }

  if (values.a !== '' && values.b === '') {
    return -1;
  }

  if (typeof values.a !== 'string' && typeof values.b === 'string') {
    return 1;
  } else if (typeof values.a === 'string' && typeof values.b !== 'string') {
    return -1;
  } else if (typeof values.a !== 'string' && typeof values.b !== 'string') {
    return 0;
  }

  console.log(values.a, values.b);

  const result = collator.compare(values.a.toLowerCase(), values.b.toLowerCase());

  if (result < 0) {
    return sortColumns[index].sortOrder === 'ASC' ? -1 : 1;
  } else if (result > 0) {
    return sortColumns[index].sortOrder === 'ASC' ? 1 : -1;
  }

  return 0;
}
