<div class="notification__rich-text-wrapper">
  <pp-rich-text
    class="notification__comment"
    [ppValue]="{
      richText: richText,
      plainText: plainText,
    }"
    [ppCanEdit]="false"
    [ppRichTextOptions]="richTextOptions"
  ></pp-rich-text>

  <div class="notification__respond--desktop notification__respond notification__buttons--desktop">
    <div
      ppTooltip
      [ppTitle]="'respond' | ppTranslate"
      class="notification__respond-button"
      *ngIf="respondableNotification && !respondingToComment && !ppCommentSent && commentsEditable"
      (click)="respond($event)"
    >
      <img
        class="notification__respond-image--grey notification__respond-button"
        [src]="EIconPath.NOTIFICATION_RESPOND"
      />
      <img
        class="notification__respond-image--blue notification__respond-button"
        [src]="EIconPath.NOTIFICATION_RESPOND_BLUE"
      />
    </div>

    <div
      ppTooltip
      ppTitle="Comment Sent"
      class="notification__sent-button"
      *ngIf="ppCommentSent && !respondingToComment"
    >
      <img class="notification__sent-button" [src]="EIconPath.NOTIFICATION_SENT_TICK" />
    </div>

    <div
      ppTooltip
      [ppTitle]="
        ppReaction?.like.includes(activeUser.userId)
          ? ('unlike_comment' | ppTranslate)
          : ('like_comment' | ppTranslate)
      "
      class="notification__like-comment-button"
      (click)="likeComment($event)"
      *ngIf="
        (ppNotification.pushNotificationType === ENotificationType.POINT_EDITION_COMMENT ||
          ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_COMMENT) &&
        ppNotification.changeBody?.commentId
      "
    >
      <img
        class="notification__like-comment-button-image"
        *ngIf="ppReaction?.like.includes(activeUser.userId)"
        [src]="EIconPath.INTERACTION_LIKE_FILLED"
      />
      <img
        class="notification__like-comment-button-image"
        *ngIf="!ppReaction?.like.includes(activeUser.userId)"
        [src]="EIconPath.INTERACTION_LIKE"
      />
    </div>
  </div>
</div>
