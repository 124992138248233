import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TPushNotification } from '../../notification.model';
import { getNotificationReasonText } from '../../utils/notification-reason';

@Component({
  selector: 'pp-notification-multi-list',
  templateUrl: './notification-multi-list.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationMultiListComponent implements OnInit {
  @Input() ppNotification: TPushNotification;

  notificationReason: string;
  EIconPath = EIconPath;

  ngOnInit(): void {
    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);
    this.filterOldAndNewData(
      this.ppNotification.changeBody.oldValue,
      this.ppNotification.changeBody.newValue,
    );
  }

  added: string[] = [];
  removed: string[] = [];

  filterOldAndNewData(oldData: string[], newData: string[]): void {
    const combinedSet = new Set([...(oldData || []), ...(newData || [])]);
    const oldDataSet = new Set(oldData);
    const newDataSet = new Set(newData);

    for (const item of combinedSet) {
      if (!newDataSet.has(item)) {
        this.removed.push(item);
      }

      if (!oldDataSet.has(item)) {
        this.added.push(item);
      }
    }
  }
}
