import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import {
  TCustomField,
  TCustomFieldList,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import {
  TAdvancedFilterListValue,
  TAdvancedFilterMultiListValue,
} from '../../../models/advanced-filter.model';
import { CustomFieldMultiListSelectDropdownComponent } from 'src/app/project/modules/custom-fields/custom-field-multi-list-select-dropdown/custom-field-multi-list-select-dropdown.component';
import {
  generateMultiListValueFromSelectedIds,
  keepOnlyTopLevelSelected,
} from 'src/app/project/modules/custom-fields/custom-field-multi-list-select/custom-field-multi-list-select-utils';

@Component({
  selector: 'pp-advanced-filter-multi-list-input',
  templateUrl: './advanced-filter-multi-list-input.component.html',
  styleUrls: ['./advanced-filter-multi-list-input.component.scss'],
})
export class AdvancedFilterMultiListInputComponent implements OnChanges {
  @Input() ppSelected: TAdvancedFilterMultiListValue;
  @Input() ppField: TCustomField;
  @Output() ppSelectCf = new EventEmitter();

  EIconPath = EIconPath;
  id: string;

  dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(private dropdownService: DropdownService) {}

  ngOnChanges(): void {
    this.id =
      'filtercfmultilist' + this.ppField.id.toString() + this.ppField.workspaceId.toString();
  }

  showList(): void {
    this.dropdownService.setData({
      field: this.ppField,
      selected: this.ppSelected?.selectedIds || [],
    });

    if (this.dropdown.visible && this.dropdown.buttonId === this.id) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.showDropdown(this.id, CustomFieldMultiListSelectDropdownComponent, {
        suppressScrollbar: true,
        callback: (item) => {
          if (item) {
            const topLevelItems = keepOnlyTopLevelSelected(this.ppField.subList, item);
            const sort = generateMultiListValueFromSelectedIds(this.ppField.subList, item);

            const sortedVals = this.sortValues(sort);
            this.selectCf(sortedVals, topLevelItems);
          }
        },
      });
    }
  }

  selectCf(value: string, selectedIds: string[]): void {
    this.ppSelected = { id: this.ppField.id, label: this.ppField.label, value, selectedIds };
    this.ppSelectCf.emit(this.ppSelected);
  }

  sortValues(vals: string): string {
    let unsortedVals = vals.split(', ');

    return unsortedVals.sort().join(', ');
  }
}
