import { EIconPath } from '../../shared/enums/icons.enum';
import { ECustomFieldType } from './custom-field-types-enums';
import { EIntegrationStatus, TCustomField } from './custom-fields.model';

export type TCustomFieldTypeData = {
  value: ECustomFieldType;
  text: string;
  description: string;
  descriptionLong: string;
  iconPath: EIconPath;
  showExamples: boolean;
};

export const customFieldTypesData: TCustomFieldTypeData[] = [
  {
    value: ECustomFieldType.TEXT,
    text: 'Text',
    description: '',
    descriptionLong: 'A single line text field for short phrases of information.',
    iconPath: EIconPath.ICON_FIELDS_TEXT_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.RICHTEXT,
    text: 'Rich Text',
    description: '',
    descriptionLong:
      'Multiple line text field using rich text options, such as bold, italics and numbering.',
    iconPath: EIconPath.ICON_FIELDS_RICH_TEXT_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.LIST,
    text: 'List',
    description: '',
    descriptionLong:
      'Create a simple list or sub-lists of predefined options where one option can be selected.',
    iconPath: EIconPath.ICON_FIELDS_LIST_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.CHECKBOX,
    text: 'Checkbox',
    description: '',
    descriptionLong: 'Single checkbox that is shown in the table.',
    iconPath: EIconPath.ICON_FIELDS_CHECKBOX_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.MULTI_LIST,
    text: 'Multi-Select List',
    description: '',
    descriptionLong:
      'Create a simple multi-select list or sub-lists of predefined options where multiple options can be selected.',
    iconPath: EIconPath.ICON_FIELDS_MULTI_LIST_24,
    showExamples: false,
  },
  {
    value: ECustomFieldType.DATE,
    text: 'Date',
    description: '',
    descriptionLong: 'For dates selected from a calendar',
    iconPath: EIconPath.ICON_FIELDS_DATE_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.TIME,
    text: 'Time',
    description: '',
    descriptionLong: 'Track time in days, hours, and minutes.',
    iconPath: EIconPath.ICON_FIELDS_TIME_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.COST,
    text: 'Cost',
    description: '',
    descriptionLong: 'For monetary data.',
    iconPath: EIconPath.ICON_FIELDS_COST_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.NUMBERS,
    text: 'Numbers',
    description: '',
    descriptionLong: 'For numerical data with options for setting decimal places.',
    iconPath: EIconPath.ICON_FIELDS_NUMBERS_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.PERCENTAGE,
    text: 'Percentage',
    description: '',
    descriptionLong: 'Enter a whole integer from 0-100.',
    iconPath: EIconPath.ICON_FIELDS_PERCENTAGE_24,
    showExamples: null,
  },
  {
    value: ECustomFieldType.TIMELINE,
    text: 'Timeline',
    description: '',
    descriptionLong: 'Add a start and end date to points which can be viewed on a Timeline.',
    iconPath: EIconPath.ICON_FIELDS_TIMELINE_24_NEW,
    showExamples: null,
  },
  {
    value: ECustomFieldType.FORMULA,
    text: 'Formulas',
    description: '',
    descriptionLong:
      'A formula custom field allows you to display a dynamic value on your site. The value can be a result of up to four existing custom fields combined mathematically, and include as many constant numerical values as required. Use brackets as you would in a mathematical expression.',
    iconPath: EIconPath.ICON_FIELDS_FORMULA_24,
    showExamples: true,
  },
];

export const humanizedTypeNames = {
  TEXT: 'Text',
  LIST: 'List',
  DATE: 'Date',
  COST: 'Cost',
  NUMBERS: 'Numbers',
  RICHTEXT: 'Rich Text',
  TIME: 'Time',
  PERCENTAGE: 'Percentage',
  CHECKBOX: 'Checkbox',
  TIMELINE: 'Timeline',
  FORMULA: 'Formula',
  MULTI_LIST: 'Multi-Select List',
};

export const customFieldIconSrc = {
  TEXT: EIconPath.CUSTOM_FIELD_TEXT_ACTIVITY,
  DATE: EIconPath.CUSTOM_FIELD_DATE_ACTIVITY,
  COST: EIconPath.CUSTOM_FIELD_COST_ACTIVITY,
  LIST: EIconPath.CUSTOM_FIELD_LIST_ACTIVITY,
  NUMBERS: EIconPath.CUSTOM_FIELD_NUMBERS_ACTIVITY,
  RICHTEXT: EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY,
  PERCENTAGE: EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY,
  TIME: EIconPath.CUSTOM_FIELD_TIME_ACTIVITY,
  CHECKBOX: EIconPath.CUSTOM_FIELD_CHECKBOX_ACTIVITY,
  TIMELINE: EIconPath.CUSTOM_FIELD_TIMELINE_ACTIVITY,
  FORMULA: EIconPath.CUSTOM_FIELD_FORMULA_ACTIVITY,
  MULTI_LIST: EIconPath.CUSTOM_FIELD_MULTI_LIST_ACTIVITY,
};

export const customFieldIconSrcBlue = {
  TEXT: EIconPath.ICON_FIELDS_TEXT_14,
  DATE: EIconPath.ICON_FIELDS_DATE_14,
  COST: EIconPath.ICON_FIELDS_COST_14,
  LIST: EIconPath.ICON_FIELDS_LIST_14,
  NUMBERS: EIconPath.ICON_FIELDS_NUMBERS_14,
  RICHTEXT: EIconPath.ICON_FIELDS_RICH_TEXT_14,
  PERCENTAGE: EIconPath.ICON_FIELDS_PERCENTAGE_14,
  TIME: EIconPath.ICON_FIELDS_TIME_14,
  CHECKBOX: EIconPath.ICON_FIELDS_CHECKBOX_14,
  TIMELINE: EIconPath.ICON_FIELDS_TIMELINE_14,
  FORMULA: EIconPath.ICON_FIELDS_FORMULA_14,
  MULTI_LIST: EIconPath.ICON_FIELDS_MULTI_LIST_14,
};

export function getCustomFieldIconSrc(customField: TCustomField): EIconPath {
  if (!customField) return this.customFieldImgSrc.TEXT;

  const volyIntegrations = customField.volyIntegrationActive;

  switch (volyIntegrations) {
    case EIntegrationStatus.OFF:
      return customFieldIconSrc[customField.type];
    case EIntegrationStatus.ON:
      return EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_BIG;
    case EIntegrationStatus.DISABLED:
      return EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_BIG;
  }
}
