import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { TCustomFieldFilter, TMultiListFilter } from '../site-filter.model';
import { checkMultilistForMatches } from '../utils/checkMultilistForMatches';

export function filterMultiListCustomField(
  filterField: TCustomFieldFilter,
  pointCustomField: TPointCustomField,
  customFields: TAllCustomFields,
): boolean {
  const multiListFilter = filterField.multiListFilter;

  if (!multiListFilter || !multiListFilter.value) return true;

  if (multiListFilter.combineValues) {
    return multiListFilter.value === pointCustomField.value;
  } else {
    return searchSublist(pointCustomField, filterField.multiListFilter);
  }
}

function searchSublist(
  pointCustomField: TPointCustomField,
  filterLabels: TMultiListFilter,
): boolean {
  const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

  const selectedLabels = checkMultilistForMatches(customField.subList, filterLabels.selectedIds);

  const pointValues = pointCustomField.value?.split(', ') || [];

  for (let i = 0; i < pointValues.length; i++) {
    if (selectedLabels.includes(pointValues[i])) return true;
  }
  return false;
}
