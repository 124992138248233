import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DeviceService } from 'src/app/core/services/device.service';
import { DropdownService } from '../../../components/dropdown/dropdown-service/dropdown.service';
import {
  deselectIfChildrenUnselected,
  propagateSelection,
  toggleItemAndDescendants,
  updateSelectionsBasedOnChildren,
} from '../custom-field-multi-list-select/custom-field-multi-list-select-utils';
import { TCustomField, TCustomFieldList } from '../custom-fields.model';

@Component({
  selector: 'pp-custom-field-multi-list-select-dropdown',
  templateUrl: './custom-field-multi-list-select-dropdown.component.html',
  styleUrl: './custom-field-multi-list-select-dropdown.component.scss',
})
export class CustomFieldMultiListSelectDropdownComponent implements OnInit {
  dropdown: TDropdown;
  filterListItemsBy = '';

  isMobile = false;
  scrolledToBottom = false;
  dropdownWidth: string = '305px';
  selectedIds: string[];
  field: TCustomField;

  constructor(
    private dropdownService: DropdownService,
    private deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.dropdown = this.dropdownService.getDropdown();
    this.isMobile = this.deviceService.isMobile();

    this.setDataFromDropdown();

    if (this.isMobile) {
      this.dropdownWidth = 'unset';
    }
  }

  select(item: TCustomFieldList): void {
    if (this.selectedIds.includes(item.id)) {
      this.selectedIds = toggleItemAndDescendants([item], item.id, false, this.selectedIds);
    } else {
      this.selectedIds = toggleItemAndDescendants([item], item.id, true, this.selectedIds);
    }

    this.selectedIds = deselectIfChildrenUnselected(this.field.subList, this.selectedIds);
    this.selectedIds = updateSelectionsBasedOnChildren(this.field.subList, this.selectedIds);

    this.dropdown.callback(this.selectedIds);
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  handleScroll(scrolledToBottom: boolean): void {
    this.scrolledToBottom = scrolledToBottom;
  }

  clearList(): void {
    this.dropdown.callback(null);
    this.hideDropdown();
  }

  setSearchBox(keyword: string): void {
    this.filterListItemsBy = keyword;
  }

  private setDataFromDropdown(): void {
    this.field = this.dropdown.data.field;
    this.selectedIds = propagateSelection(this.field.subList, this.dropdown.data.selected);

    if (this.dropdown.data.width) {
      this.dropdownWidth = this.dropdown.data.width;
    }
  }
}
