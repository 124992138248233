import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { sortBoolean } from './sort-boolean';
import { sortNumbers } from './sort-numbers';
import { sortText } from './sort-text';
import { sortTitle } from './sort-title';
import { sort } from './sorting';

export function sortCustomField(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  const customFieldAIndex = Object.keys(firstPoint.customFieldsMap).find((_customFieldId) => {
    const customField = GET_CUSTOM_FIELDS()[_customFieldId];

    if (customField.label === sortColumns[index].name) {
      if (!sortColumns[index].customFieldIds) {
        return true;
      } else if (
        sortColumns[index].customFieldIds &&
        sortColumns[index].customFieldIds.includes(
          firstPoint.customFieldsMap[_customFieldId].customFieldTemplateId.toString(),
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  });

  const customFieldBIndex = Object.keys(secondPoint.customFieldsMap).find((_customFieldId) => {
    const customField = GET_CUSTOM_FIELDS()[_customFieldId];

    if (customField.label === sortColumns[index].name) {
      if (!sortColumns[index].customFieldIds) {
        return true;
      } else if (
        sortColumns[index].customFieldIds &&
        sortColumns[index].customFieldIds.includes(
          secondPoint.customFieldsMap[_customFieldId].customFieldTemplateId.toString(),
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  });

  const customFieldA = firstPoint.customFieldsMap[customFieldAIndex];
  const customFieldB = secondPoint.customFieldsMap[customFieldBIndex];

  let a = null;
  let b = null;
  let type = null;

  if (customFieldA) {
    a = customFieldA.value;

    const fieldA = GET_CUSTOM_FIELDS()[customFieldA.customFieldTemplateId];

    type = fieldA.type;
  }

  if (customFieldB) {
    b = customFieldB.value;

    const fieldB = GET_CUSTOM_FIELDS()[customFieldB.customFieldTemplateId];

    type = fieldB.type;
  }

  let result: number;

  switch (type) {
    case ECustomFieldType.NUMBERS:
    case ECustomFieldType.COST:
    case ECustomFieldType.DATE:
    case ECustomFieldType.PERCENTAGE:
    case ECustomFieldType.FORMULA:
    case ECustomFieldType.TIME:
      result = sortNumbers(a, b, sortColumns, index, {});
      break;
    case ECustomFieldType.TEXT:
    case ECustomFieldType.RICHTEXT:
    case ECustomFieldType.LIST:
    case ECustomFieldType.MULTI_LIST:
      result = sortText(a, b, sortColumns, index, {});
      break;
    case ECustomFieldType.CHECKBOX:
      if (a === 'true') {
        a = true;
      } else if (a === 'false') {
        a = false;
      }

      if (b === 'true') {
        b = true;
      } else if (b === 'false') {
        b = false;
      }

      result = sortBoolean(a, b, sortColumns, index);
      break;
    case ECustomFieldType.TIMELINE: {
      const firstFieldValue = a ? a.split('~')[0] : null;
      const secondFieldValue = b ? b.split('~')[0] : null;

      result = sortNumbers(firstFieldValue, secondFieldValue, sortColumns, index, {});
      break;
    }
    default:
      result = 0;
      break;
  }

  if (result) {
    return result;
  }

  if (sortColumns[index + 1]) {
    return sort(firstPoint, secondPoint, sortColumns, index + 1);
  } else {
    return sortTitle(firstPoint, secondPoint, sortColumns, index);
  }
}
