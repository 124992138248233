<input
  #daysInput
  preventPointSwitching
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="inputDays"
  *ngIf="ppCanEdit && !ppShowHoursOnly"
  placeholder="day"
  (ppFormatted)="updateDaysInput($event)"
  (keydown.enter)="blurInput($event)"
/>

<input
  #hoursInput
  preventPointSwitching
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="inputHours"
  *ngIf="ppCanEdit"
  placeholder="hr"
  (ppFormatted)="updateHoursInput($event)"
  (keydown.enter)="blurInput($event)"
/>

<input
  #minutesInput
  preventPointSwitching
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="inputMinutes"
  *ngIf="ppCanEdit"
  placeholder="min"
  (ppFormatted)="updateMinutesInput($event)"
  (keydown.enter)="blurInput($event)"
/>
