<div class="pointCF__field" [ngSwitch]="volyIntegrationActive">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img
      *ngSwitchCase="EIntegrationStatus.OFF"
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.ICON_FIELDS_MULTI_LIST_24"
    />

    <img
      *ngSwitchCase="EIntegrationStatus.ON"
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL"
    />

    <img
      *ngSwitchCase="EIntegrationStatus.DISABLED"
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL"
    />

    <span class="pointCF__label-container"> {{ ppCustomField?.label }} </span>
  </label>

  <div class="pointCF__content" #list>
    <div
      #pointFieldsList
      id="{{ 'cbulkMultiList' + ppFieldId.toString() }}"
      class="pointCF__input pointCF__input--list"
      [ngClass]="{ 'pointCF__input--focus': focused }"
      [class.pointCF__input--error]="error"
      (click)="showList()"
    >
      <span *ngIf="ppFieldValue">{{ ppFieldValue }}</span>
      <span class="pointCF__input-list-placeholder" *ngIf="!ppFieldValue">{{
        'select...' | ppTranslate
      }}</span>
    </div>

    <pp-save-indicator [ppFocused]="focused" [ppFilled]="!!ppFieldValue" [ppUpdating]="updating">
    </pp-save-indicator>
  </div>
</div>
