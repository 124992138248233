import { Component, Input, OnDestroy } from '@angular/core';
import { TActivityResponse, TCommentResponseData } from '@project/view-models';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { finalize, Subject, takeUntil, tap } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TRichTextOptions } from 'src/app/project/components/input/rich-text/rich-text.consts';
import { TRichTextUpdate } from 'src/app/project/components/input/rich-text/rich-text.model';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TUser } from 'src/app/project/modules/user/user.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { EAccessField } from 'src/app/project/shared/enums/access-field.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointsUsersService } from '../../../../points-users.service';
import { CommentDeleteDropdownComponent } from '../../comment-delete-dropdown/comment-delete-dropdown.component';
import { CommentActivityService } from './comment-activity.service';

@Component({
  selector: 'pp-comment-activity',
  templateUrl: './comment-activity.component.html',
  styleUrl: './comment-activity.component.scss',
})
export class CommentActivityComponent implements OnDestroy {
  @Input() ppLast: boolean;
  @Input() ppPointId: string;
  @Input() ppComment: any;
  @Input() ppShareOption: string;
  public date = new Date().getTime();

  private readonly destroy$ = new Subject<void>();

  EIconPath = EIconPath;
  EUserRole = EUserRole;
  userListIds: string[] = [];

  richTextOptions: TRichTextOptions = {
    showButtons: true,
    defaultBorder: true,
    small: false,
    bulkChanges: false,
  };

  comment: TCommentResponseData & TActivityResponse;
  commentId: string;
  user: TUser;
  timeStampHover: string;
  formattedDateTime: string;
  commentDeleteStillValid: boolean;
  commentEditStillValid: boolean;
  canEdit: boolean;
  editingComment: boolean;
  processing: boolean;
  alreadyEdited: boolean;
  updatedDate: string;
  private commentBackup: any;

  constructor(
    private userService: UserService,
    private dropdownService: DropdownService,
    private preferencesService: PreferencesService,
    private permissionsService: PermissionsService,
    private commentActivityService: CommentActivityService,
    private pointsUsersService: PointsUsersService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {
    this.user = this.userService.getUser();
  }

  ngDoCheck(): void {
    const commentBackup = this.ppComment;

    if (!isEqual(this.commentBackup, commentBackup)) {
      this.commentBackup = commentBackup;
      this.ngOnChanges();
    }
  }

  ngOnChanges(): void {
    if (!this.ppComment) {
      return;
    }

    this.initComment();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private initComment(): void {
    if (this.ppComment.data) {
      this.comment = this.ppComment.data as TCommentResponseData & TActivityResponse;
    } else {
      this.comment = this.ppComment as TCommentResponseData & TActivityResponse;
    }

    this.commentId = this.ppComment._id || this.ppComment.data.childRef.id;
    const createdById =
      this.ppComment.header.createdBy.id || this.ppComment.header.createdBy.userId;

    const format = this.preferencesService.getPreferences().dateFormat;
    const date = this.ppComment.header.createdEpochMillis;
    const editDate = this.ppComment.header.updatedEpochMillis;

    this.alreadyEdited = date !== editDate;
    this.updatedDate = this.alreadyEdited
      ? transformDate({
          value: editDate,
          inputHourFormat: ' [at] hh:mm a',
          format: format,
          localTime: false,
        })
      : null;
    const dateDayJs = dayjs(date);

    this.timeStampHover = transformDate({
      value: date,
      inputHourFormat: ' [at] hh:mm a',
      format: format,
      localTime: false,
    });

    if (
      dateDayJs.isBetween(dayjs().subtract(1, 'm'), dayjs()) ||
      new Date().getTime() - date.valueOf() <= 0
    ) {
      this.formattedDateTime = 'Just now';
    } else if (dateDayJs.isBetween(dayjs().subtract(7, 'd'), dayjs())) {
      this.formattedDateTime = dateDayJs.fromNow();
    } else {
      this.formattedDateTime = this.timeStampHover;
    }

    this.commentDeleteStillValid = this.date - date < 86400000 * 2; // 48h
    this.commentEditStillValid = this.date - date < 86400000; //24h

    const permissions = this.permissionsService.getCommentPermissions(
      this.ppComment.workspaceRef.id,
    );

    this.canEdit = this.user.userId === createdById && permissions.edit;
    this.updateUserList();
  }

  showDeleteCommentDropdown(commentId: string, buttonId: string): void {
    const dropdown = this.dropdownService.getDropdown();

    if (dropdown.visible && dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({
        commentId,
      });

      this.dropdownService.showDropdown(buttonId, CommentDeleteDropdownComponent, {
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false,
            },
          },
        ],
      });
    }
  }

  editComment(): void {
    this.editingComment = true;
  }

  stopEditing(): void {
    this.editingComment = false;
  }

  updateComment(event: TRichTextUpdate): void {
    if (this.processing) {
      return;
    }

    if (!(event.mergedText?.trim().length > 0)) {
      const prompt = this.translationPipe.transform('prompt_comment_empty_error');

      this.promptService.showWarning(prompt);
      this.stopEditing();
      return;
    }

    this.processing = true;

    this.commentActivityService
      .editComment({
        commentId: this.commentId,
        workspaceId: this.ppComment.workspaceRef.id,
        pointId: this.ppPointId,
        body: {
          comment: event.mergedText,
          commentRich: event.richText,
          mentions: event.mentions,
        },
      })
      .pipe(
        (tap(() => {
          this.editingComment = false;
        }),
        finalize(() => {
          this.processing = false;
        })),
      )
      .subscribe();
  }

  updateUserList(): void {
    this.pointsUsersService
      .fetchUsersWithAccessToPoint(this.ppPointId, EAccessField.COMMENT)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userIds) => {
        this.userListIds = userIds;
      });
  }
}
