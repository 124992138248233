<pp-point-heading
  id="timelineHeading"
  (ppOnRendered)="onTimelineHeadingRendered($event)"
  data-m-target="Point timeline section"
  *ngIf="commentsVisible || timelineVisible"
>
  <ng-container *ngIf="!commentsOnly" heading> {{ 'activity' | ppTranslate }} </ng-container>

  <ng-container *ngIf="commentsOnly" heading> {{ 'comments' | ppTranslate }} </ng-container>

  <ng-container secondary *ngIf="commentsVisible && timelineVisible">
    <button
      class="timeline__activity-type"
      [ngClass]="{ 'timeline__activity-type--selected': !commentsOnly }"
      (click)="showAllActivities()"
    >
      {{ 'all' | ppTranslate }}
    </button>

    <button
      class="timeline__activity-type"
      [ngClass]="{ 'timeline__activity-type--selected': commentsOnly }"
      (click)="showCommentsOnly()"
    >
      {{ 'only_comments' | ppTranslate }}
    </button>
  </ng-container>
</pp-point-heading>

<div
  class="timeline__container"
  *ngIf="!commentsOnly || (comments && comments.data.length > 0)"
  [ngClass]="isBrowserSafari ? 'timeline__safariMobileSpacing' : ''"
>
  <ng-container *ngIf="!commentsOnly">
    <div
      class="timeline__activity"
      *ngFor="
        let activityData of activityData.data;
        let first = first;
        let last = last;
        let index = index
      "
    >
      <ng-container
        *ngIf="commentsVisible || !(activityData.data.childRef?.type === 'DefectComment')"
      >
        <span class="timeline__avatar-wrapper">
          <img
            ppTooltip
            class="timeline__avatar timeline__avatar--default"
            draggable="false"
            [src]="EIconPath.PLACEHOLDER_USER"
            *ngIf="!(activityData.header.createdBy.primaryImageId && !isAvatarDefault)"
            ppTitle="{{ activityData.header.createdBy.caption }}"
          />

          <img
            ppTooltip
            ppImage
            class="timeline__avatar"
            draggable="false"
            [ppSecureSrc]="
              'api/v1/images/' +
              activityData.header.createdBy.primaryImageId +
              '/file/size/square/40'
            "
            *ngIf="activityData.header.createdBy.primaryImageId && !isAvatarDefault"
            (error)="showDefaultAvatar()"
            ppTitle="{{
              activityData.header.createdBy.caption || activityData.header.createdBy['userName']
            }}"
          />
        </span>

        <div
          class="timeline__activity-item timeline__activity-item--other"
          [class.timeline__activity-item--last]="last"
          *ngIf="activityData.data.childRef?.type != 'DefectComment'"
        >
          <div class="timeline__activity-content">
            <!-- new point activity -->
            <span
              class="timeline__new-point"
              *ngIf="activityData.activityType === 'NEW_DOCUMENT' && !activityData.data.comment"
            >
              <span class="activityChange__icon sw-icon sw-icon-new-point"></span>
              <span>{{ 'added_this_point' | ppTranslate }}</span>
            </span>
            <!-- / new point activity end -->

            <!-- other activities -->
            <ng-container
              *ngIf="
                activityData.data.changeList?.length > 0 &&
                !(activityData.activityType === 'NEW_DOCUMENT')
              "
            >
              <div
                class="timeline__activity-change"
                *ngFor="let change of activityData.data.changeList; let index = index"
              >
                <pp-activity-change
                  [ppChange]="change"
                  [ppVersion]="fetchedActivitiesVersion"
                  [ppCustomFieldId]="change.cfTemplateId"
                  [ppWorkspaceId]="ppWorkspaceId"
                  [ppAuthor]="activityData.header.createdBy.caption"
                  *ngIf="
                    !change.propName ||
                    ((change.propName !== 'description' ||
                      ((activityData.data.changeList[index + 1]?.propName !== 'descriptionRich' ||
                        (activityData.data.changeList[index + 1]?.propName === 'descriptionRich' &&
                          activityData.data.changeList[index + 1]?.newValue !== '' &&
                          !activityData.data.changeList[index + 1]?.newValue)) &&
                        (index - 1 < 0 ||
                          activityData.data.changeList[index - 1]?.propName !== 'descriptionRich' ||
                          (activityData.data.changeList[index - 1]?.propName ===
                            'descriptionRich' &&
                            activityData.data.changeList[index - 1]?.newValue !== '' &&
                            !activityData.data.changeList[index - 1]?.newValue)))) &&
                      (change.propName !== 'descriptionRich' ||
                        (change.propName === 'descriptionRich' && change.newValue)))
                  "
                ></pp-activity-change>
              </div>
            </ng-container>
            <!-- / other activities end -->
          </div>

          <div
            ppTooltip
            [ppTitle]="activityData.header.timeStampHover"
            class="timeline__activity-datetime hide--mobile"
          >
            {{ activityData.header.formattedDatetime }}
            <i aria-hidden="true" class="fa fa-clock timeline__activity-datetime-icon"></i>
          </div>
        </div>

        <pp-comment-activity
          *ngIf="activityData.data.childRef?.type === 'DefectComment'"
          [ppLast]="last"
          [ppComment]="activityData"
          [ppShareOption]="workspace?.share.shareOption"
          [ppPointId]="ppPointId"
        ></pp-comment-activity>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="commentsOnly && comments.data.length > 0">
    <div
      class="timeline__activity"
      *ngFor="let comment of comments.data; let last = last; let index = index"
    >
      <span class="timeline__avatar-wrapper">
        <img
          [src]="EIconPath.PLACEHOLDER_USER"
          class="timeline__avatar"
          draggable="false"
          [hidden]="comment.header.createdBy.primaryImageId"
        />

        <img
          ppTooltip
          ppImage
          class="timeline__avatar"
          draggable="false"
          [ppSecureSrc]="
            'api/v1/images/' + comment.header.createdBy.primaryImageId + '/file/size/square/40'
          "
          *ngIf="comment.header.createdBy.primaryImageId"
          ppTitle="{{ comment.header.createdBy.caption }}"
        />
      </span>
      <pp-comment-activity
        [ppLast]="last"
        [ppComment]="comment"
        [ppShareOption]="workspace?.share.shareOption"
        [ppPointId]="ppPointId"
      ></pp-comment-activity>
    </div>
  </ng-container>

  <pp-point-activity-placeholder
    *ngIf="processing || !ppDetailsFetched"
  ></pp-point-activity-placeholder>

  <div class="timeline__load-more-button-container">
    <pp-button
      ppStyle="secondary"
      ppId="timelineLoadMoreBtn"
      (ppAction)="loadMore()"
      ppSize="small"
      [ppDisabled]="ppAttachmentUploading"
      [ppProcess]="processing"
      *ngIf="
        activityData.pageNumber < activityData.pagination?.totalPages &&
        activityData.fetched &&
        !commentsOnly
      "
    >
      {{ 'load_more' | ppTranslate }}
    </pp-button>
  </div>
</div>

<div
  class="timeline__no-content"
  *ngIf="commentsOnly && comments && !(comments.data.length > 0) && !processing"
>
  <div class="timeline__no-content-icon">
    <span class="activityChange__icon timeline__no-content-icon--inner">
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_COMMENT" />
    </span>
  </div>

  {{ 'no_comments_found_1' | ppTranslate }}
  <strong>{{ 'no_comments_found_2' | ppTranslate }}</strong>
  {{ 'no_comments_found_3' | ppTranslate }}
</div>

<div class="timeline__no-timeline" *ngIf="!timelineVisible"></div>
