import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EActivityPropName } from '../../../../../../shared/enums/activity-prop-name.enum';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';

@Component({
  selector: 'pp-multi-select-activity',
  templateUrl: './multi-select-activity.component.html',
  styleUrls: ['./multi-select-activity.component.scss'],
})
export class MultiSelectActivityComponent implements OnInit {
  @Input() ppChange;

  EActivityPropName = EActivityPropName;
  ECustomFieldType = ECustomFieldType;
  EIconPath = EIconPath;

  added: string[] = [];
  removed: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.filterOldAndNewData(this.ppChange.oldValue, this.ppChange.newValue);
  }

  filterOldAndNewData(oldData: string[], newData: string[]): void {
    const combinedSet = new Set([...(oldData || []), ...(newData || [])]);
    const oldDataSet = new Set(oldData);
    const newDataSet = new Set(newData);

    for (const item of combinedSet) {
      if (!newDataSet.has(item)) {
        this.removed.push(item);
      }

      if (!oldDataSet.has(item)) {
        this.added.push(item);
      }
    }
  }
}
