import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TPointCustomField, TPointUpdate } from '@project/view-models';
import { isEqual } from 'lodash';
import { catchError, finalize, of, Subject, takeUntil, tap } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { CustomFieldMultiListSelectDropdownComponent } from 'src/app/project/modules/custom-fields/custom-field-multi-list-select-dropdown/custom-field-multi-list-select-dropdown.component';
import {
  generateMultiListValueFromSelectedIds,
  keepOnlyTopLevelSelected,
} from 'src/app/project/modules/custom-fields/custom-field-multi-list-select/custom-field-multi-list-select-utils';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointsUpdateService } from '../../../points-update.service';
import { UpdatePointCustomField } from '../../../points.actions';
import { TPoint } from '../../../points.model';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

@Component({
  selector: 'pp-point-fields-multi-list',
  templateUrl: './point-fields-multi-list.component.html',
  styleUrl: './point-fields-multi-list.component.scss',
})
export class PointFieldsMultiListComponent implements OnDestroy {
  @Input() ppField: TPointCustomField;
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppFieldValue: string;
  @Input() ppFieldValueIds: string[];
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppVolyActive: EIntegrationStatus;

  @ViewChild('pointFieldsList') pointFieldsListElement: ElementRef;

  private readonly destroy$ = new Subject<void>();

  updating = false;
  error = false;
  focused = false;
  dropdownVisible = false;
  EIconPath = EIconPath;
  EIntegrationStatus = EIntegrationStatus;
  private fieldValueIdsBackup: string[] = [];

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private dropdownService: DropdownService,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private customFieldsService: CustomFieldsService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  showList(): void {
    if (!this.ppCanEdit) {
      return;
    }

    if (this.dropdownVisible) {
      this.hideListDropdown();
    } else {
      this.showListDropdown();
    }
  }

  clearCustomField(): void {
    this.ppFieldValue = null;
    this.updateField();
  }

  selectCustomField(itemIds: string[]): void {
    const customField = this.customFieldsService.getWorkspaceCustomField(
      this.ppWorkspaceId,
      this.ppFieldId,
    );

    if (this.ppNew) {
      const topLevelIds = keepOnlyTopLevelSelected(customField.subList, itemIds);
      this.ppFieldValue = generateMultiListValueFromSelectedIds(customField.subList, topLevelIds);
    }

    this.ppFieldValueIds = itemIds;
    this.updateField();
  }

  deselectCustomField(): void {
    this.ppFieldValue = null;
    this.ppFieldValueIds = [];
    this.updateField();
  }

  updateField(): void {
    const _id = this.ppPointId;
    const customField = this.customFieldsService.getWorkspaceCustomField(
      this.ppWorkspaceId,
      this.ppFieldId,
    );

    this.ppFieldValueIds = keepOnlyTopLevelSelected(customField.subList, this.ppFieldValueIds);

    if (this.ppNew) {
      this.updateFieldForNewPoint(
        this.ppPointId,
        this.ppFieldValue,
        this.ppFieldValueIds,
        this.ppWorkspaceId,
        this.ppFieldId,
      );

      return;
    }

    if (isEqual(this.ppFieldValueIds, this.fieldValueIdsBackup)) {
      return;
    }

    this.updating = true;

    const body: TPointUpdate = {
      customFieldsList: [
        {
          customFieldTemplateId: this.ppFieldId,
          selectedItemIds: this.ppFieldValueIds,
        },
      ],
    };

    this.pointsUpdateService
      .updatePointField(_id, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_list_update');

          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  arrowClicked(): void {
    if (!this.ppCanEdit) {
      return;
    }

    this.showList();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldValue: string,
    fieldValueIds: string[],
    workspaceId: string,
    customFieldId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: customFieldId,
        customFieldValue: fieldValue,
        customFieldValueIds: fieldValueIds,
      }),
    );
  }

  private showListDropdown(): void {
    this.focused = true;
    this.fieldValueIdsBackup = this.ppFieldValueIds ? [...this.ppFieldValueIds] : [];

    const inputElement = this.pointFieldsListElement.nativeElement.getBoundingClientRect();

    this.dropdownService.setData({
      field: this.ppField,
      selected: this.ppFieldValueIds || [],
    });

    this.dropdownService.showDropdown(
      `cmultiList${this.ppFieldId}`,
      CustomFieldMultiListSelectDropdownComponent,
      {
        callback: (item) => {
          this.ppFieldValueIds = item;
        },
        onClose: () => {
          this.onListSelectDropdownCallback(this.ppFieldValueIds);
          this.onListSelectDropdownClose();
        },
        suppressScrollbar: true,
        width: `${Math.max(225, inputElement.width)}px`,
      },
    );

    this.dropdownVisible = true;
  }

  private onListSelectDropdownClose(): void {
    this.focused = false;
    this.dropdownVisible = false;
  }

  private onListSelectDropdownCallback(item: string[]): void {
    if (item) {
      this.selectCustomField(item);
    } else {
      this.deselectCustomField();
    }

    this.focused = false;
  }

  private hideListDropdown(): void {
    this.focused = false;
    this.dropdownVisible = false;

    this.dropdownService.hideDropdown();
  }
}
