<!-- notranslate - https://github.com/quilljs/quill/issues/2286 -->

<div
  #richTextComponentContainer
  notranslate
  class="richTextComponent__container"
  (ppOnRendered)="onRichTextRendered()"
  [class.richTextComponent__container--border]="
    ppRichTextOptions.defaultBorder &&
    (!activeQuill.editing || activeQuill.fieldId !== ppId) &&
    ppCanEdit
  "
  [class.richTextComponent__container--defaultBorder]="
    !(ppRichTextOptions.defaultBorder && (!activeQuill.editing || activeQuill.fieldId !== ppId)) &&
    !ppRichTextOptions.comment
  "
  [class.richTextComponent__container--focused]="(focused || showingModal) && ppCanEdit"
  [class.richTextComponent__container--disabled]="!showDefaultStyle"
  [class.richTextComponent__container--failed]="
    error || ppRichTextOptions.characterLimit - textLength < 0
  "
  [class.richTextComponent__container--blue]="showingBlueBorder && ppCanEdit"
  [class.richTextComponent__container--field]="ppRichTextOptions.pointField"
  [class.richTextComponent__container--noBorder]="showDefaultStyle && !ppCanEdit"
  [class.richTextComponent__container--noPointerEvents]="
    ppRichTextOptions.processing && ppRichTextOptions.notification
  "
  [class.richText--comment]="ppRichTextOptions.comment"
  [class.richText--marginBottom]="ppRichTextOptions.showButtons && showDefaultStyle && editing"
  [class.richTextComponent__small]="
    ppRichTextOptions.small && (!activeQuill.editing || activeQuill.fieldId !== ppId)
  "
  [class.richTextComponent__notification]="ppRichTextOptions.notification"
  [class.richTextComponent__container--bulk]="ppRichTextOptions.bulkChanges"
  [class.richText--clearable]="ppRichTextOptions.clearable"
  [class.richText--hideToolbars]="!editing"
>
  <app-spinner
    [ppInputTitle]="true"
    [ppHidden]="!ppRichTextOptions.processing || ppRichTextOptions.notification"
  ></app-spinner>

  <i
    class="fas fa-check richTextComponent__saved-check"
    aria-hidden="true"
    *ngIf="
      ppRichTextOptions.success && !ppRichTextOptions.clearable && !ppRichTextOptions.notification
    "
  ></i>

  <div #richTextComponent (click)="activateRichText($event)"></div>

  <pp-save-indicator
    *ngIf="
      !ppRichTextOptions.processing &&
      ppRichTextOptions.clearable &&
      !ppRichTextOptions.notification
    "
    [ppFilled]="!!ppValue?.richText"
    [ppUpdating]="false"
    [ppShowClearBtn]="ppCanEdit"
    (ppAction)="clearField()"
    [ppFocused]="activeQuill.editing && activeQuill.fieldId === ppId"
  ></pp-save-indicator>

  <div
    #richTextComponentToolbar
    (mousedown)="startEditing('user')"
    class="richTextComponent__toolbar"
    [class.richTextComponent__toolbar--visible]="
      (ppCanEdit && !ppRichTextOptions.processing) || ppRichTextOptions.notification || showingModal
    "
  >
    <button class="ql-bold"></button>
    <button class="ql-italic"></button>
    <button class="ql-underline"></button>
    <button class="ql-strike"></button>
    <button class="ql-link"></button>
    <button class="ql-list" value="ordered"></button>
    <button class="ql-list" value="bullet"></button>
    <button (click)="showMentionsDropdown($event)">&#64;</button>

    <div
      class="richTextComponent__characterLimit"
      [class.richTextComponent__characterLimit--green]="
        ppRichTextOptions.characterLimit - textLength >= 0
      "
      [class.richTextComponent__characterLimit--red]="
        ppRichTextOptions.characterLimit - textLength < 0
      "
      *ngIf="ppRichTextOptions.characterLimit - textLength < 500"
    >
      {{ textLength }} / {{ ppRichTextOptions.characterLimit }}
    </div>
  </div>

  <div
    class="description__actions telegramSendButton"
    *ngIf="ppRichTextOptions.showButtons && ppCanEdit && editing"
  >
    <pp-button ppId="cancelDescriptionEditBtn" (ppAction)="cancel($event)" ppStyle="text">
      {{ 'cancel' | ppTranslate }}
    </pp-button>

    <pp-button
      ppStyle="primary"
      ppId="updateDescriptionBtn"
      (ppAction)="update(true)"
      [ppProcess]="ppRichTextOptions.processing"
    >
      {{ (ppRichTextOptions.notification ? 'comment' : 'update') | ppTranslate }}
    </pp-button>
  </div>

  <div
    class="richTextComponent__processing"
    *ngIf="
      ppRichTextOptions.notification && (ppRichTextOptions.processing || ppRichTextOptions.success)
    "
  >
    <div class="richText_spinner" *ngIf="!ppRichTextOptions.success">
      <app-spinner [ppNotification]="true"></app-spinner>
    </div>

    <div class="richTextComponent__processing__sent" *ngIf="ppRichTextOptions.success">
      <img
        class="richTextComponent__processing__sent--img"
        [src]="EIconPath.NOTIFICATION_SENT_TICK"
      />

      {{ 'comment_sent' | ppTranslate }}
    </div>
  </div>
</div>
