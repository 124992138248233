<div class="checkbox" (click)="toggleCheckbox()">
  <div
    class="checkbox__input-box"
    [ngClass]="class"
    [class.selected]="(ppChecked || ppHalfChecked) && !ppDisabled"
    [class.selected--disabled]="(ppChecked || ppHalfChecked) && ppDisabled"
    [class.checkbox--solid-selected]="ppType === 'solid' && (ppChecked || ppHalfChecked)"
  >
    <span
      [class.checkbox__check-wrapper--large]="ppSize === 'large'"
      [class.checkbox__check-wrapper--medium]="ppSize === 'medium'"
    >
      <pp-icon
        [style.visibility]="ppChecked || ppHalfChecked ? 'visible' : 'hidden'"
        class="checkbox__check"
        [ppClass]="ppSize === 'large' ? 'setSize18 displayFlex' : 'setSize12 displayFlex'"
        [ppSrc]="src"
        [ppColor]="
          ppDisabled && ppType === 'solid'
            ? 'white'
            : ppDisabled
              ? 'grey-400'
              : ppType === 'solid'
                ? 'white'
                : 'primary-600'
        "
      ></pp-icon>
    </span>
  </div>

  <div class="checkbox__label" [class.checkbox__label--solid]="ppType === 'solid'">
    <ng-content></ng-content>
  </div>
</div>
