<div class="pointCF__content" #customFieldElement>
  <div
    class="customFieldCost"
    [class.pointCF__input--focus]="focused"
    [class.pointCF__input--disabled]="!ppCanEdit"
    [class.pointCF__input--integration]="
      ppIntegrationStatus === EIntegrationStatus.ON ||
      ppIntegrationStatus === EIntegrationStatus.DISABLED
    "
  >
    <div
      class="customFieldNumeric__unitWrapper"
      [class.customFieldNumeric__unitWrapper--hidden]="!ppFieldCurrencyCode"
    >
      <span
        class="customFieldNumeric__unit"
        *ngIf="ppFieldCurrencyCode"
        ppTooltip
        [ppTitle]="currencyTooltip"
      >
        {{ ppFieldCurrencyCode }}
      </span>

      <pp-calculator-icon *ngIf="ppCanEdit"></pp-calculator-icon>
    </div>

    <input
      [ppAutonumeric]="autonumericOptions"
      class="customFieldCost__input"
      preventPointSwitching
      [class.customFieldCost__input--noUnit]="!ppFieldCurrencyCode"
      #costInput
      [(ngModel)]="ppFieldValue"
      (ppFormatted)="updateField($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.disabled]="ppCanEdit ? null : true"
      [attr.readonly]="ppCanEdit ? null : true"
      [placeholder]="placeholder"
      (keydown)="onKeyDown($event)"
      (keydown.enter)="blurInput($event)"
    />

    <pp-save-indicator
      [ppFocused]="focused"
      [ppFilled]="ppFieldValue?.length > 0"
      [ppUpdating]="updating"
      [ppShowClearBtn]="ppCanEdit"
      (ppAction)="clearCustomField()"
    ></pp-save-indicator>
  </div>
</div>
