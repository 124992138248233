import { Base64 } from 'js-base64';
import Quill from 'quill';
import 'quill-mention';

import {
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  RendererFactory2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { TAnyFunction } from '@core/helpers';
import { PointFieldsService } from 'src/app/project/modules/points/point-modal/point-fields/point-fields.service';
import { PointModalService } from 'src/app/project/modules/points/point-modal/point-modal.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { ModalService } from '../../modal/modal.service';
import { PromptService } from '../../prompt/prompt.service';

import { DOCUMENT } from '@angular/common';
import { ClickOutsideHandler, WindowService } from '@core/services';
import { TAllUsers } from '@project/view-models';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { getCrossbrowserEventPath } from '../../../../core/helpers/compose-event-path';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { EIconPath } from '../../../shared/enums/icons.enum';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from '../../confirm-modal/confirm-modal.component';
import { generatePlainText } from './generate-plain-text';
import { richTextFormats } from './rich-text-formats';
import { TRichText } from './rich-text-ops.model';
import { TRichTextOptions } from './rich-text.consts';
import { TRichTextEditorOptions, TRichTextUpdate, TRichTextUserList } from './rich-text.model';
import { checkEnableQuill } from './utils/check-enabled-quill';
import { generateRenderItem } from './utils/generate-render-item';
import { generateSource } from './utils/generate-source';
import { fixDragAndDrop } from './utils/quill-workaround';
import { setUserList } from './utils/set-user-list';

@Component({
  selector: 'pp-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
})
export class RichTextComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('richTextComponentContainer', { static: true }) richTextComponentContainer: ElementRef;
  @ViewChild('richTextComponent', { static: true }) richTextComponentElement: ElementRef;
  @ViewChild('richTextComponentToolbar', { static: true })
  richTextComponentToolbarElement: ElementRef;

  @Input() ppValue: {
    richText: string;
    plainText: string;
  };

  // TODO: ppId to be replaced with uuid generated ID (available after feature/user-management is merged)
  @Input() ppId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppPointId = '';
  @Input() ppPlaceholder: string;
  @Input() ppDisabledPlaceholder = '—';
  @Input() ppCanEdit: boolean;
  @Input() ppUserListIds: string[];
  @Input() ppRichTextOptions: TRichTextOptions = {
    characterLimit: 5000,
  };
  @Input() ppAutofocus: boolean;

  @Output() ppUpdate = new EventEmitter<TRichTextUpdate>();
  @Output() ppCancel = new EventEmitter();
  @Output() ppUpdateUserList = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private readonly mouseUp$ = new Subject<void>();
  private mouseUpDebounceTimeMs = 100;
  // TODO: create a separate RTE class that will be responsible for the whole quill logic; share it with comments
  private quill: Quill;
  private clickOutsideHandler: ClickOutsideHandler;
  private startedClickInside = false;
  activeQuill = this.pointFieldsService.getIsEditingQuill();
  mergedText = null;
  textLength = 0;
  focused = false;
  showingModal = false;
  showDefaultStyle: boolean;

  private userList: TRichTextUserList[] = [];
  private users: TAllUsers;

  user$: Observable<TUser>;
  user: TUser;

  error = false;
  editing = false;
  changed = false;
  existingMentions: string[] = [];
  showingBlueBorder = false;

  private initialized = false;
  private renderer: Renderer2;
  EIconPath = EIconPath;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      workspaces: TWorkspacesById;
      user: TUser;
    }>,
    private usersService: UsersService,
    private modalService: ModalService,
    private pointFieldsService: PointFieldsService,
    private rendererFactory: RendererFactory2,
    private ngZone: NgZone,
    private router: Router,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointModalService: PointModalService,
    private windowService: WindowService,
    private workspaceService: WorkspaceService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));

    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.observeUser();
    this.users = this.usersService.getUsers();
    this.trySetUserList(this.ppUserListIds);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showDefaultStyle = this.ppCanEdit || this.ppRichTextOptions.disableDisabledStyle;

    if (changes.ppUserListIds) {
      this.trySetUserList(this.ppUserListIds);
    }

    if (
      (changes.ppSuccess && Object.keys(changes).length === 1) ||
      (this.focused && !changes.ppPointId)
    ) {
      return;
    }

    if (!(this.ppRichTextOptions.bulkChanges && !changes.ppValue.isFirstChange())) {
      this.checkEnableQuill(changes);
      this.initContent();
    }

    if (changes.ppPointId) {
      this.ppUpdateUserList.emit();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();

    if (this.quill) {
      this.quill.setContents();
    }
  }

  private observeUser(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      if (!this.focused && this.initialized) {
        this.initEditor();
      }
    });
  }

  private checkEnableQuill(changes: SimpleChanges): void {
    if (!this.quill) {
      return;
    }

    if (checkEnableQuill(changes, this.ppCanEdit, this.ppRichTextOptions.processing)) {
      this.quill.enable();
    } else {
      this.quill.disable();
    }

    this.quill.setContents();
  }

  initContent(event?: MouseEvent): void {
    if (this.quill) {
      this.focused = false;
      this.setQuillText();
    }

    if (
      event &&
      event.target instanceof HTMLElement &&
      (event.target.id === 'closeModalBtn_halfModal' || event.target.id === 'closeModalBtn_icon')
    ) {
      this.ngZone.run(() => this.router.navigate(['/site', this.ppWorkspaceId]));
    }

    this.textLength = 0;
    this.editing = false;
    this.pointFieldsService.setIsEditingQuill(false, this.ppId);
    this.showingBlueBorder = false;
  }

  private setQuillText(): void {
    if (this.ppValue?.richText) {
      this.setRichText();
    } else if (this.ppValue?.plainText) {
      this.setPlainText();
    } else {
      this.setEmptyText();
    }
  }

  private setRichText(): void {
    // There is a try catch here because old rich text notifications return rich text, new rich text notifications
    // return plain text, both are in "value" field and we have no way of telling them apart until parsing
    try {
      const richText = JSON.parse(Base64.decode(this.ppValue.richText));

      this.existingMentions = [];

      if (!this.ppRichTextOptions.notification) {
        richText.ops.forEach((textLine) => {
          if (textLine.insert && textLine.insert.mention) {
            this.existingMentions.push(textLine.insert.mention.id);

            if (this.users[textLine.insert.mention.id]) {
              textLine.insert.mention.value = this.users[textLine.insert.mention.id].userName;
            }
          }
        });
      }

      this.quill.setContents(richText.ops);
    } catch (error) {
      const plainText = this.ppValue.richText;

      if (this.ppRichTextOptions.addQuotation) {
        this.quill.setText('"' + plainText.trim() + '"');
      } else {
        this.quill.setText(plainText);
      }
    }
  }

  private setPlainText(): void {
    this.existingMentions = [];

    if (this.ppRichTextOptions.addQuotation) {
      this.quill.setText('"' + this.ppValue.plainText.trim() + '"');
    } else {
      this.quill.setText(this.ppValue.plainText);
    }
  }

  private setEmptyText(): void {
    this.existingMentions = [];

    this.quill.setText('');
  }

  private trySetUserList(userIds: string[]): void {
    this.users = this.usersService.getUsers();

    if (!this.users) {
      return;
    }

    if (userIds === undefined) {
      const workspace = this.workspaceService.getWorkspace(this.ppWorkspaceId);
      this.userList = setUserList(this.users, workspace ? workspace.users : []);
    } else {
      this.userList = setUserList(this.users, userIds);
    }
  }

  update(shouldCloseEditor: boolean = false): void {
    const plainText = generatePlainText(this.quill.getContents());

    if (plainText.trim() === '') {
      this.clearField(shouldCloseEditor);
    } else {
      const richTextComponent = this.quill.getContents();
      const mergedText = this.generateMergedText(richTextComponent);

      this.checkIfTextExceedsCharacterLimit(mergedText);

      if (this.error) {
        return;
      }

      this.mergedText = mergedText;

      this.updateField(shouldCloseEditor, {
        richTextComponent,
        mergedText,
      });
    }
  }

  clearField(shouldCloseEditor: boolean = false): void {
    if (shouldCloseEditor) {
      this.stopEditing();
    }

    this.ppUpdate.emit({
      mergedText: null,
      richText: null,
      mentions: [],
    });
  }

  private generateMergedText(richTextComponent: any): string {
    let mergedText = '';

    richTextComponent.ops.forEach((textLine) => {
      if (typeof textLine.insert === 'string') {
        mergedText += textLine.insert;
      } else if (textLine.insert.mention) {
        mergedText += '@' + textLine.insert.mention.value;
      }
    });

    return mergedText;
  }

  private updateField(
    shouldCloseEditor: boolean = false,
    { richTextComponent, mergedText }: { richTextComponent: any; mergedText: string },
  ): void {
    const richTextComponentBase64 = Base64.encode(JSON.stringify(richTextComponent));

    if (shouldCloseEditor) {
      this.stopEditing();
    }

    this.ppUpdate.emit({
      mergedText: mergedText.trim(),
      richText: richTextComponentBase64,
      mentions: this.generateQuillMentions(richTextComponent),
    });
  }

  private generateQuillMentions(richTextComponent: any): string[] {
    const mentions = [];

    richTextComponent.ops.forEach((textLine) => {
      if (textLine.insert && textLine.insert.mention) {
        mentions.push(textLine.insert.mention.id);
      }
    });

    this.existingMentions.forEach((existingMention) => {
      const mentionToDeleteIndex = mentions.findIndex(
        (searchedMention) => searchedMention === existingMention,
      );

      if (mentionToDeleteIndex > -1) {
        mentions.splice(mentionToDeleteIndex, 1);
      }
    });

    return mentions;
  }

  private checkIfTextExceedsCharacterLimit(mergedText: string): void {
    const textExceedsCharacterLimit = mergedText.length > this.ppRichTextOptions.characterLimit;

    if (textExceedsCharacterLimit) {
      const promptText = this.translationPipe.transform('prompt_text_over_limit', {
        // TODO: format numbers so 5000 is 5,000
        characterLimit: this.ppRichTextOptions.characterLimit,
      });
      this.error = true;

      this.promptService.showWarning(promptText);
    } else {
      this.error = false;
    }
  }

  initEditor(): void {
    fixDragAndDrop();

    this.quill = new Quill(this.richTextComponentElement.nativeElement, this.generateRTEOptions());

    if (!this.ppCanEdit) {
      this.quill.disable();
    }

    this.initContent();
    this.selectionChangeListener();
    this.textChangeListener();

    if (this.ppRichTextOptions.notification) {
      this.quill.focus();
      this.quill.setSelection(Number.MAX_SAFE_INTEGER, 0);
    }

    const richTextComponent = this.quill.getContents();
    const mergedText = this.generateMergedText(richTextComponent);

    this.textLength = mergedText.length;
  }

  private generateRTEOptions(): TRichTextEditorOptions {
    return {
      modules: {
        toolbar: this.richTextComponentToolbarElement.nativeElement,
        mention: {
          allowedChars: /^[a-zA-Z0-9_.-@+]*$/,
          mentionDenotationChars: ['@'],
          source: (searchTerm: string, renderList: TAnyFunction): void => {
            generateSource(searchTerm, renderList, this.userList);
          },
          positioningStrategy: 'fixed',
          isolateCharacter: true,
          renderItem: (item) => generateRenderItem(item, this.users),
        },
      },
      theme: 'snow',
      placeholder: this.ppCanEdit ? this.ppPlaceholder : this.ppDisabledPlaceholder,
      formats: richTextFormats,
    };
  }

  private selectionChangeListener(): void {
    this.quill.on('selection-change', (range, oldRange) => {
      this.onQuillSelectionChange(range, oldRange);
    });
  }

  private onQuillSelectionChange(range: any, oldRange: any): void {
    if (range === null && oldRange !== null) {
      if (!this.editing) {
        this.pointFieldsService.setIsEditingQuill(false, this.ppId);
        this.showingBlueBorder = false;
      }
    }

    if (!range) {
      this.focused = false;

      if (!this.ppRichTextOptions.showButtons && !this.ppRichTextOptions.notification) {
        this.update(false);
      }
    } else if (!this.ppRichTextOptions.processing) {
      const editingQuill = this.pointFieldsService.getIsEditingQuill();

      if (this.ppId !== editingQuill.fieldId) {
        this.pointFieldsService.setIsEditingQuill(true, this.ppId);

        this.showingBlueBorder = true;
        this.focused = true;
      }
    }
  }

  private textChangeListener(): void {
    this.quill.on('text-change', (event: TRichText, oldDelta: TRichText, source: string) => {
      this.onQuillTextChange(source);
    });
  }

  private onQuillTextChange(source: string): void {
    if (source === 'user' && !this.ppRichTextOptions.processing) {
      this.startEditing(source);
    }
  }

  removeClickListener = (): void => {
    this.changed = false;

    this.clickOutsideHandler.disable();
  };

  showMentionsDropdown(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.quill.getModule('mention').openMenu(' @');
  }

  activateRichText(event: MouseEvent): void {
    const path = getCrossbrowserEventPath(event);

    const { clickLink, clickTooltip } = this.checkQuillElementClicked(path);

    if (this.quill && !clickLink && !clickTooltip) {
      this.quill.focus();
    }

    if (this.ppRichTextOptions.notification && this.clickOutsideHandler) {
      this.clickOutsideHandler.enable();
    }
  }

  private checkQuillElementClicked(path: EventTarget[]): {
    clickLink: boolean;
    clickTooltip: boolean;
  } {
    let clickLink = false;
    let clickTooltip = false;

    if (path) {
      path.forEach((element) => {
        if (element instanceof HTMLElement && element.classList) {
          for (let j = 0; j < element.classList.length; j++) {
            if (element.classList[j] === 'ql-tooltip') {
              clickTooltip = true;

              break;
            }
          }
        }

        if (element['href']) {
          clickLink = true;

          if (!this.editing && this.ppCanEdit) {
            // our logic for turned off quill that can be edited prevents events
            this.windowService.open(element['href']);
          }
        }
      });
    }
    return { clickLink, clickTooltip };
  }

  stopEditing(): void {
    this.editing = false;

    this.pointFieldsService.setIsEditingQuill(false, this.ppId);
    this.showingBlueBorder = false;
    this.focused = false;

    this.quill.blur();
    this.removeClickListener();
  }

  startEditing(source: string): void {
    const canEdit = source === 'user' && !this.ppRichTextOptions.processing;

    if (canEdit) {
      const richTextComponent = this.quill.getContents();
      this.editing = true;
      this.showingBlueBorder = true;
      this.focused = true;

      // TODO: move to where it's used
      this.pointFieldsService.setIsEditingQuill(true, this.ppId);

      const mergedText = this.generateMergedText(richTextComponent);

      this.textLength = mergedText.length;

      if (this.textLength <= this.ppRichTextOptions.characterLimit) {
        this.error = false;
      }

      if (!this.changed) {
        this.clickOutsideHandler.enable();
        this.changed = true;
      }

      if (this.ppRichTextOptions.bulkChanges) {
        this.update(false);
      }
    }
  }

  private onClickOutside(event: MouseEvent): void {
    if (!this.startedClickInside) {
      this.clickOutsideHandler.disable();
      event.preventDefault();
      event.stopImmediatePropagation();

      if (this.ppRichTextOptions.showButtons) {
        this.onClickOutsideConfirm(event);
      } else {
        this.onClickOutsideAutosave();
      }
    }
  }

  private onClickOutsideConfirm(event: MouseEvent): void {
    this.showingBlueBorder = true;
    this.showingModal = true;

    this.quill.blur();
    this.showConfirmCloseModal(event);
  }

  private showConfirmCloseModal(event: MouseEvent): void {
    this.setConfirmCloseModalDate();

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      closeWarning: true,
      callback: () => {
        this.onConfirmModalCallback(event);
      },
      onClose: (succeeded: boolean, closeTransitionPromise: Promise<void>): void => {
        this.onConfirmModalClose(succeeded, closeTransitionPromise);
      },
    });
  }

  private setConfirmCloseModalDate(): void {
    // TODO: move message to where it's used
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('close_cf_without_saving_confirm'),
      heading: this.translationPipe.transform('confirm'),
      redButton: true,
      confirmText: this.translationPipe.transform('close_without_saving'),
    };

    this.modalService.setData(modalData);
  }

  private onConfirmModalCallback(event: MouseEvent): void {
    this.editing = false;
    this.error = false;

    this.initContent(event);
    this.removeClickListener();
    // TODO: not canceled in the modal
    this.ppCancel.emit();
  }

  private onConfirmModalClose(succeeded: boolean, closeTransitionPromise: Promise<void>): void {
    this.showingBlueBorder = false;
    this.showingModal = false;

    if (!succeeded) {
      // TODO: move to where it's used
      const scrollPosition = this.pointModalService.getScrollPosition();

      closeTransitionPromise.finally(() => {
        this.clickOutsideHandler.enable();
      });

      this.quill.focus();
      // TODO: move message to where it's used
      this.pointModalService.updateScrollPosition(scrollPosition);
    }
  }

  private onClickOutsideAutosave(): void {
    this.showingBlueBorder = false;
    this.focused = false;

    this.removeClickListener();
    this.update(false);
  }

  onRichTextRendered(): void {
    this.initialized = true;
    this.initEditor();

    this.richTextComponentContainer.nativeElement.addEventListener('mousedown', () => {
      this.startedClickInside = true;
    });

    this.mouseUp$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.mouseUpDebounceTimeMs),
        tap(() => {
          this.startedClickInside = false;
        }),
      )
      .subscribe();

    this.document.addEventListener('mouseup', () => {
      this.mouseUp$.next();
    });

    this.clickOutsideHandler = new ClickOutsideHandler(
      this.richTextComponentContainer.nativeElement,
      this.destroy$,
      {
        mouseEventTypes: 'click',
        enabled: this.ppRichTextOptions.notification,
      },
    );

    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });

    if (this.ppAutofocus) {
      this.startEditing('user');
    }
  }

  cancel(event: MouseEvent): void {
    this.removeClickListener();
    this.initContent();

    this.focused = false;
    this.error = false;
    this.showingBlueBorder = false;

    this.pointFieldsService.setIsEditingQuill(false, this.ppId);

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.quill.blur();
      });
    });

    if (this.ppCancel) {
      this.ppCancel.emit(event);
    }
  }
}
