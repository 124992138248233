import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { SharesApiProviderService } from '@core/api';
import { TGuid } from '@core/helpers';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from '../../services/analytics/google-analytics.consts';
import { TWorkspaceUser } from '../../view-models/user.view-model';
import { TAccountLimits } from '../account/account.model';
import { ResponseErrorService } from '../errors/response-error.service';
import { UserService } from '../user/user.service';
import { UsersService } from '../users/users.service';
import { GET_USERS } from '../users/users.store';
import { setChangedWorkspace, setChangedWorkspaces } from '../workspace/workspace';
import { UpdateWorkspaceShare, UpdateWorkspaceUsers } from '../workspace/workspace.actions';
import { TWorkspace } from '../workspace/workspace.model';
import { GET_WORKSPACE } from '../workspace/workspace.store';
import { EUserRole } from './share-utils/user-roles';
import {
  TCustomFieldAccessOption,
  TGranularPermission,
  TImportShareWorkspaceUsersDTO,
  TNewUser,
  TShare,
  TUpdateShareDTO,
} from './share.model';
import { generateShare } from './share.utils';
import { ADD_SHARE, GET_SHARES, SET_SHARES } from './shares.store';

export type TShares = {
  promise: Promise<TShare[]>;
  firstFetch: boolean;
  fetching: boolean;
  fetched: boolean;
};

export type TWorkspaceShares = {
  data: TShare[];
  defectTags: string[];
  advancedAccessLevels: {
    customFields: TCustomFieldAccessOption[];
    tags: {
      permission: TGranularPermission;
    };
    timeline: {
      permission: {
        read: boolean;
      };
      comments: {
        permission: TGranularPermission;
      };
    };
    exports: {
      permission: {
        read: boolean;
      };
    };
    sitePlan: {
      permission: {
        read: boolean;
      };
    };
  };
  workspaceId: string;
  promise: Promise<TShare[]>;
  firstFetch: boolean;
  fetching: boolean;
  fetched: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class SharesService {
  private shares: TShares;
  private readonly workspaceShares: TWorkspaceShares;

  constructor(
    private responseErrorService: ResponseErrorService,
    private sharesApiProviderService: SharesApiProviderService,
    private userService: UserService,
    private usersService: UsersService,
    private store: Store,
    private router: Router,
  ) {
    this.shares = {
      promise: null,
      firstFetch: true,
      fetching: false,
      fetched: false,
    };

    this.workspaceShares = {
      data: [],
      defectTags: [],
      advancedAccessLevels: {
        customFields: [],
        tags: {
          permission: {
            read: true,
            edit: true,
          },
        },
        timeline: {
          permission: {
            read: true,
          },
          comments: {
            permission: {
              read: true,
              edit: true,
            },
          },
        },
        exports: {
          permission: {
            read: true,
          },
        },
        sitePlan: {
          permission: {
            read: true,
          },
        },
      },
      workspaceId: null,
      promise: null,
      firstFetch: true,
      fetching: false,
      fetched: false,
    };
  }

  //
  // Shares
  //

  clearShares(): void {
    this.shares.promise = null;
    this.shares.firstFetch = true;
    this.shares.fetching = false;
    this.shares.fetched = false;
  }

  fetchShares(refetch: boolean = false): Promise<TShare[]> {
    if (this.shares.firstFetch || (refetch && !this.shares.fetching)) {
      this.shares.firstFetch = false;
      this.shares.fetching = true;

      this.shares.promise = this.sharesApiProviderService
        .getAll(refetch)
        .pipe(
          tap(() => {
            // it's a force fetch/refetch anyway because of that if so we need to get it from backend every time
            this.shares.fetched = true;
          }),
          catchError(this.responseErrorService.handleRequestError),
          finalize(() => {
            this.shares.fetching = false;
          }),
        )
        .toPromise();
    }

    return this.shares.promise;
  }

  updateShare(shareId: string, share: TUpdateShareDTO): Observable<TShare> {
    return this.sharesApiProviderService
      .simpleUpdateShare(shareId, share)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  deleteShare(shareId: TGuid): Observable<void> {
    return this.sharesApiProviderService
      .deleteShare(shareId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  resendInvitation(shareId: string): Observable<void> {
    return this.sharesApiProviderService
      .resendShare(shareId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  //
  // Workspace shares
  //

  getWorkspaceShares(): TWorkspaceShares {
    return this.workspaceShares;
  }

  setWorkspaceShares(data: TShare[], workspaceId: string): void {
    this.workspaceShares.data = data;

    if (workspaceId) {
      this.workspaceShares.workspaceId = workspaceId;
    }
  }

  clearWorkspaceShares(): void {
    this.workspaceShares.data.length = 0;
    this.workspaceShares.workspaceId = null;
    this.workspaceShares.promise = null;
    this.workspaceShares.firstFetch = true;
    this.workspaceShares.fetching = false;
    this.workspaceShares.fetched = false;
  }

  fetchWorkspaceShare(workspaceId: string): Observable<TShare> {
    return this.sharesApiProviderService.getWorkspaceShare(workspaceId).pipe(
      map((response) => {
        const share = generateShare(response, workspaceId, GET_WORKSPACE(workspaceId));
        ADD_SHARE(share);

        return response;
      }),
    );
  }

  fetchWorkspaceShares(
    workspaceId: string,
    {
      refetch = false,
      forceFetch = false,
      update = true,
    }: {
      refetch?: boolean;
      forceFetch?: boolean;
      update?: boolean;
    } = {},
  ): Promise<TShare[]> {
    if (
      this.workspaceShares.firstFetch ||
      (refetch && !this.workspaceShares.fetching) ||
      forceFetch ||
      (workspaceId !== this.workspaceShares.workspaceId && !this.workspaceShares.fetching)
    ) {
      this.workspaceShares.firstFetch = false;
      this.workspaceShares.fetching = true;

      this.workspaceShares.promise = this.sharesApiProviderService
        .getTargetShares(workspaceId)
        .pipe(
          tap((response) => {
            this.workspaceShares.fetched = true;

            if (update) {
              const shares: TShare[] = [];

              response.forEach((share) => {
                shares.push(generateShare(share, workspaceId, GET_WORKSPACE(workspaceId)));
              });

              this.setWorkspaceShares(shares, workspaceId);
            }
          }),
          catchError(this.responseErrorService.handleRequestError),
          finalize(() => {
            this.workspaceShares.fetching = false;
          }),
        )
        .toPromise();
    }

    return this.workspaceShares.promise;
  }

  addWorkspaceShare(workspaceId: string, share: TShare): void {
    this.workspaceShares.workspaceId = workspaceId;
    this.workspaceShares.data.push(share);
  }

  sortWorkspaceShares(): void {
    this.workspaceShares.data = this.sortUsers(this.workspaceShares.data);
  }

  removeWorkspaceShare(workspaceId: string, shareId: TGuid): void {
    const deletedShareIndex = this.workspaceShares.data.findIndex(
      (oldShare) => oldShare.shareId === shareId,
    );

    this.workspaceShares.data.splice(deletedShareIndex, 1);
  }

  removeWorkspaceShares(selectedSharesIds: string[]): void {
    this.workspaceShares.data = this.workspaceShares.data.filter(
      (share) => !selectedSharesIds.includes(share.shareId),
    );
  }

  updateWorkspaceShare(userId: TGuid, share: TShare): void {
    const editedShareIndex = this.workspaceShares.data.findIndex(
      (oldShare) => oldShare.userId === userId,
    );

    this.workspaceShares.data[editedShareIndex] = share;

    if (this.userService.getUser().userId === userId) {
      this.updateSelfShare(share);
    }
  }

  private updateSelfShare(share: TShare): void {
    const shares = GET_SHARES();

    const shareIndex = shares.findIndex(
      (searchedShare) => searchedShare.workspaceId === share.workspaceId,
    );

    shares[shareIndex] = share;

    SET_SHARES(shares);

    this.store.dispatch(
      new UpdateWorkspaceShare({
        workspaceId: share.workspaceId,
        share,
      }),
    );
  }

  sortUsers(users: TShare[]): TShare[] {
    const allUsers = GET_USERS();

    return users.sort((a, b) => {
      const userA = allUsers[a.userId];
      const userB = allUsers[b.userId];

      if (!userA || !userA?.userName) {
        return -1;
      }

      if (!userB || !userB?.userName) {
        return 1;
      }

      return userA.userName.toLowerCase() > userB.userName.toLowerCase() ? 1 : -1;
    });
  }

  importShares(
    targetWorkspaceId: string,
    shareUserList: TImportShareWorkspaceUsersDTO,
  ): Observable<TShare[]> {
    return this.sharesApiProviderService
      .importShareWorkspaceUsers(targetWorkspaceId, shareUserList)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  importUsersFromExcel(workspace: TWorkspace, excel: File): Observable<TNewUser[]> {
    const formData = new FormData();

    formData.append('file', excel);

    return this.sharesApiProviderService.importExcelShares(workspace.workspaceId, formData).pipe(
      tap((response) => {
        response.forEach((user) => {
          const userToAdd = cloneDeep(user);

          this.setCreatedUser(workspace, userToAdd, workspace.workspaceId);
          this.addWorkspaceShares([userToAdd.share]);
        });

        logEventInGTAG(EGoogleEventSettings.SETTINGS__USER__EXCEL, {
          event_category: EGoogleEventCategory.SETTINGS,
        });

        setChangedWorkspaces(true);
        setChangedWorkspace(workspace.workspaceId);
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  setCreatedUser(workspace: TWorkspace, newUser: TNewUser, workspaceId: string): void {
    const users = this.usersService.getUsers();
    const newWorkspace = cloneDeep(workspace);

    if (newWorkspace.users) {
      newWorkspace.users.push(newUser.user.userId);

      if (!users[newUser.user.userId]) {
        const userToAdd: TWorkspaceUser = {
          email: newUser.user.email,
          lastActivityEpochMillis: newUser.user.lastActivityEpochMillis,
          userName: newUser.user.name,
          userId: newUser.user.userId,
          verified: newUser.user.verified,
          avatarPublicUrl: newUser.user?.avatarPublicUrl,
        };

        this.usersService.setUsers([userToAdd]);
      }

      setChangedWorkspace(workspaceId, true);

      this.store.dispatch(
        new UpdateWorkspaceUsers({
          workspaceId,
          users: newWorkspace.users,
        }),
      );
    }
  }

  addWorkspaceShares(shares: TShare[]): void {
    if (this.router.url.startsWith('/settings/site/')) {
      const urlArray = this.router.url.split('/');

      shares.forEach((share) => {
        this.addWorkspaceShare(urlArray[3], share);
      });

      this.sortWorkspaceShares();
    }
  }

  addLimitToIncrease(shareOption: EUserRole): TAccountLimits {
    const newLimit: TAccountLimits = {
      SITES: 0,
      SHARES_ACCOUNT_ADMIN: 0,
      SHARES_ADMIN: 0,
      SHARES_GUEST: 0,
      SHARES_NORMAL: 0,
    };

    switch (shareOption) {
      case EUserRole.ACCOUNT_ADMIN:
        newLimit.SHARES_ACCOUNT_ADMIN += 1;
        break;
      case EUserRole.SITE_ADMIN:
        newLimit.SHARES_ADMIN += 1;
        break;
      case EUserRole.LIMITED:
        newLimit.SHARES_GUEST += 1;
        break;
      case EUserRole.NORMAL:
        newLimit.SHARES_NORMAL += 1;
        break;
    }

    return newLimit;
  }
}
