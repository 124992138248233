import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TCustomFieldAccessOption, TShare } from '../../../../share/share.model';
import { PrepareShareService } from '../account-user-modal-shares-list/account-user-modal-shares-list-share/prepare-share.service';
import { EditAccountUserModalDataService } from '../edit-account-user-modal-data.service';

@Component({
  selector: 'pp-account-user-modal-custom-fields',
  templateUrl: './account-user-modal-custom-fields.component.html',
  styleUrls: ['./account-user-modal-custom-fields.component.scss'],
})
export class AccountUserModalCustomFieldsComponent {
  @Input() ppWorkspaceId: string;
  @Input() ppShare: TShare;
  @Output() ppShareChange = new EventEmitter<TShare>();

  share: TShare;
  EIconPath = EIconPath;
  shareBackup: TShare;

  constructor(
    private prepareShareService: PrepareShareService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private customFieldsService: CustomFieldsService,
  ) {}

  ngOnInit(): void {
    this.prepareShare();
  }

  updateShare(): void {
    if (this.ppShare) {
      this.ppShareChange.emit(this.share);
    }
    this.editAccountUserModalDataService.setShare(this.ppWorkspaceId, this.share);
  }

  updateCustomField(index: number, customField: TCustomFieldAccessOption): void {
    this.share.advancedAccessLevels.customFields[index] = customField;
    this.updateShare();
  }

  checkCFVisibility(customField: TCustomFieldAccessOption): boolean {
    return !!this.customFieldsService.getWorkspaceCustomField(
      this.ppWorkspaceId,
      customField.templateId,
    );
  }

  private prepareShare(): void {
    if (this.ppShare) {
      this.share = this.ppShare;
    } else {
      this.share = this.prepareShareService.prepareShare(this.ppWorkspaceId);
    }
  }
}
