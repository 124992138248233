import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';

import { TCustomFieldList } from '../custom-fields.model';

import { cloneDeep } from 'lodash';
import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';
import { DeviceService } from 'src/app/core/services/device.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

export type TListElement = {
  id?: string;
  label: string;
  currencyCode?: string;
  type?: string;
  unit?: string;
  decimalPlaces?: string | number;
  showTotal?: string;
  showCommas?: boolean;
  showHoursOnly?: boolean;
  readOnly?: string;
  fieldError?: boolean;
  level?: number;
  text?: string;
  subValuesActive?: boolean;
} & TCustomFieldList;

@Component({
  selector: 'pp-custom-field-list-select',
  templateUrl: './custom-field-list-select.component.html',
  styleUrls: ['./custom-field-list-select.component.scss'],
})
export class CustomFieldListSelectComponent implements OnInit, OnChanges {
  @Input() ppItem: TCustomFieldList;
  @Input() ppField: TCustomFieldList;
  @Input() ppLevel = 0;
  @Input() ppSelectedId: string;
  @Input() ppFilter = '';
  @Output() ppSelect = new EventEmitter();
  @Output() ppOnScroll = new EventEmitter();

  expanded: {
    [itemId: string]: boolean;
  } = {};
  matchedListItems: Array<TCustomFieldList> = [];
  EIconPath = EIconPath;

  public isiPod = false;
  public isMobile = false;
  public isiPad = false;

  private path: string[] = [];
  private pathFound = false;

  constructor(private deviceService: DeviceService) {}

  ngOnInit(): void {
    this.isiPod = this.deviceService.isiPod();
    this.isMobile = this.deviceService.isMobile();
    this.isiPad = this.deviceService.isiPad();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }): void {
    if (changes.ppFilter && !changes.ppFilter.isFirstChange()) {
      this.ppFilter = this.ppFilter.toLowerCase();
      this.filterListItems();
    }
  }

  onTableRendered(element: HTMLElement): void {
    if (element.scrollHeight === element.clientHeight) {
      this.ppOnScroll.emit(true);
    }
  }

  select(listItem: TCustomFieldList, level?: number): void {
    const currentLevel = 0;

    const item: TCustomFieldList = { ...listItem };
    const itemLevel = level || this.ppLevel;

    this.path.length = 0;
    this.pathFound = false;

    this.findPath(this.ppField.subList, currentLevel, item, itemLevel);

    item.path = this.path.join('/');
    this.ppSelect.emit(item);
  }

  selectDeep(item: TCustomFieldList): void {
    this.ppSelect.emit(item);
  }

  toggleExpand(item: TCustomFieldList): void {
    this.expanded[item.id] = !this.expanded[item.id];
  }

  scrollHandler(event: Event): void {
    const element = event.target as HTMLElement;
    const scrolledToBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    this.ppOnScroll.emit(scrolledToBottom);
  }

  private findPath(
    subList: TCustomFieldList[] | undefined,
    currentLevel: number,
    selected: TCustomFieldList,
    level: number,
  ): boolean {
    if (!subList) {
      return false;
    }

    for (let i = 0; i < subList.length; i += 1) {
      this.path[currentLevel] = subList[i].label;

      if (currentLevel < level) {
        if (subList[i].subList && subList[i].subList.length > 0) {
          this.findPath(subList[i].subList, currentLevel + 1, selected, level);
        }
      }

      if (
        this.pathFound ||
        (subList[i].label === selected.label && subList[i].id === selected.id)
      ) {
        this.pathFound = true;

        return true;
      }
    }

    return false;
  }

  private filterListItems(): void {
    this.matchedListItems = [];

    if (this.ppFilter.length) {
      const listItem: TListElement = cloneDeep(this.ppItem) as TListElement;
      const filter = this.ppFilter;

      this.findListItemByFilter(listItem, filter, 0);

      this.matchedListItems.forEach((matchedListItem) => {
        this.path = [];
        const currentLevel = 0;
        listItem.text = matchedListItem.label;
        this.pathFound = false;

        this.findPath(listItem.subList, currentLevel, matchedListItem, matchedListItem.level);

        listItem.path = this.path.join('/');
        matchedListItem.path = sanitizeHTML(this.path.join('/'));
      });
    }
  }

  private findListItemByFilter(listItem: TListElement, filter: string, level: number): void {
    const listItemCopy = { ...listItem };

    if (listItemCopy.subList && listItemCopy.subList.length > 0) {
      const nextLevel = level + 1;

      listItemCopy.subList.forEach((subItem) => {
        const subItemCopy = { ...subItem } as TListElement;

        subItemCopy.level = nextLevel;

        if (subItemCopy.label && subItemCopy.label.toLowerCase().indexOf(filter) !== -1) {
          this.matchedListItems.push(subItemCopy);
        }

        this.findListItemByFilter(subItemCopy, filter, nextLevel);
      });
    }
  }

  isSelected(id: string): boolean {
    return this.ppSelectedId === id;
  }
}
