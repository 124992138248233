import { TPoint } from 'src/app/project/modules/points/points.model';

import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { filterCheckboxCustomField } from '../filter-custom-fields/filter-checkbox-custom-field';
import { filterDateCustomField } from '../filter-custom-fields/filter-date-custom-field';
import { filterListCustomField } from '../filter-custom-fields/filter-list-custom-field';
import { filterNumericCustomField } from '../filter-custom-fields/filter-numeric-custom-field';
import { filterTimelineCustomField } from '../filter-custom-fields/filter-timeline-custom-field';
import { TFilters } from '../site-filter.model';
import { filterMultiListCustomField } from '../filter-custom-fields/filter-multi-list-custom-field';

export function checkCustomFields(
  point: TPoint,
  filters: TFilters,
  customFields: TAllCustomFields,
): boolean {
  const customFieldMatch = [];

  filters.customFields.forEach(() => {
    customFieldMatch.push(false);
  });

  let customFieldsMatch = true;

  const pointCustomFields = point.customFieldsMap || {};

  for (let index = 0; index < filters.customFields.length; index++) {
    const filterField = filters.customFields[index];
    const customField = customFields[filterField.workspaceId][filterField.id];

    const filterSite = filterField.sites.find((site) => site.workspaceId === point.workspaceRef.id);

    let pointCustomField = pointCustomFields[filterField.id];

    if (!pointCustomField) {
      pointCustomField = pointCustomFields[filterSite?.filterId];
    }

    // missing CF (in overview it's filter for field from other site)
    if (!filterSite || !pointCustomField) {
      const filterEnabled =
        filterField.appliedValues?.endDate ||
        filterField.appliedValues?.idOfChosenElement ||
        filterField.appliedValues?.max ||
        filterField.appliedValues?.min ||
        filterField.appliedValues?.path ||
        filterField.appliedValues?.startDate ||
        filterField.appliedValues?.text ||
        filterField.appliedValues?.value ||
        filterField.multiListFilter?.value;

      customFieldMatch[index] = !filterEnabled;

      continue;
    }

    // excluded
    if (filterField.excluded) {
      let fieldEmpty = !pointCustomField.value;

      if (customField?.type === ECustomFieldType.CHECKBOX) {
        fieldEmpty = !pointCustomField?.value || pointCustomField?.value === 'false';
      }

      if (!fieldEmpty) {
        return false;
      }
    }

    if (filterField && filterField.appliedValues) {
      switch (customFields[filterField.workspaceId][filterField.id].type) {
        case ECustomFieldType.DATE:
          customFieldMatch[index] = filterDateCustomField(filterField, pointCustomField);

          if (!customFieldMatch[index]) {
            return false;
          }

          break;

        case ECustomFieldType.CHECKBOX:
          customFieldMatch[index] = filterCheckboxCustomField(filterField, pointCustomField);

          if (!customFieldMatch[index]) {
            return false;
          }

          break;

        case ECustomFieldType.TIMELINE:
          customFieldMatch[index] = filterTimelineCustomField(filterField, pointCustomField);

          if (!customFieldMatch[index]) {
            return false;
          }

          break;
        case ECustomFieldType.COST:
        case ECustomFieldType.PERCENTAGE:
        case ECustomFieldType.FORMULA:
        case ECustomFieldType.NUMBERS:
        case ECustomFieldType.TIME:
          customFieldMatch[index] = filterNumericCustomField(filterField, pointCustomField);

          if (!customFieldMatch[index]) {
            return false;
          }

          break;

        case ECustomFieldType.LIST:
          customFieldMatch[index] = filterListCustomField(
            filterField,
            pointCustomField,
            customFields,
          );

          if (!customFieldMatch[index]) {
            return false;
          }

          break;

        case ECustomFieldType.MULTI_LIST:
          customFieldMatch[index] = filterMultiListCustomField(
            filterField,
            pointCustomField,
            customFields,
          );

          if (!customFieldMatch[index]) {
            return false;
          }
          break;

        case ECustomFieldType.TEXT:
          customFieldMatch[index] = true;

          if (!customFieldMatch[index]) {
            return false;
          }

          break;
        case ECustomFieldType.RICHTEXT:
          customFieldMatch[index] = true;

          if (!customFieldMatch[index]) {
            return false;
          }

          break;

        default:
          customFieldMatch[index] = true;

          break;
      }
    } else {
      switch (customFields[filterField.workspaceId][filterField.id].type) {
        case ECustomFieldType.TEXT:
        case ECustomFieldType.RICHTEXT:
          customFieldMatch[index] = true;

          break;
        default:
          customFieldMatch[index] = false;
          return false;
      }
    }
  }

  customFieldMatch.forEach((match) => {
    if (!match) {
      customFieldsMatch = false;
    }
  });

  return customFieldsMatch;
}
