<div
  class="timeline__activity-item timeline__activity-item--comment"
  [class.timeline__activity-item--last]="ppLast"
  *ngIf="ppComment"
>
  <div class="timeline__activity-content timeline__activity-content--comment">
    <span class="activityChange__icon">
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_COMMENT" />
    </span>

    <div class="activityChange--comment" [class.activityChange--commentEditing]="editingComment">
      <span>
        {{ 'added' | ppTranslate }}
        <span class="timeline__bold">{{ 'comment' | ppTranslate }}</span>
        <span class="comment_editedInfo" *ngIf="alreadyEdited" ppTooltip [ppTitle]="updatedDate">
          ({{ 'comment_edited' | ppTranslate }})</span
        >:
      </span>

      <pp-description-activity
        [ppDescription]="comment.commentRich"
        [ppPlainText]="comment.comment"
        [ppComment]="true"
        [ppPointId]="ppPointId"
        [ppId]="commentId"
        [ppShowEdit]="canEdit && !editingComment && commentEditStillValid"
        (ppEdit)="editComment()"
        *ngIf="!editingComment"
      ></pp-description-activity>

      <pp-rich-text
        (ppCancel)="stopEditing()"
        [ppAutofocus]="true"
        class="richText__container"
        [ppId]="commentId + 'edit'"
        [ppCanEdit]="true"
        [ppValue]="{ richText: comment.commentRich, plainText: comment.comment }"
        [ppWorkspaceId]=""
        ppPlaceholder="Enter text..."
        (ppUpdate)="updateComment($event)"
        [ppRichTextOptions]="richTextOptions"
        [ppUserListIds]="userListIds"
        *ngIf="editingComment"
      ></pp-rich-text>
    </div>
  </div>

  <div
    [class.timeline__activity-datetime--editing]="editingComment"
    ppTooltip
    [ppTitle]="timeStampHover"
    class="timeline__activity-datetime hide--mobile"
  >
    {{ formattedDateTime }}
    <i aria-hidden="true" class="fa fa-clock timeline__activity-datetime-icon"></i>

    <div class="comment__delete" *ngIf="ppShareOption === EUserRole.OWNER || user?.isSuperUser">
      <div
        ppTooltip
        ppTitle="Change comment"
        [id]="'deleteCommentButton' + commentId"
        class="deleteComment"
        (click)="showDeleteCommentDropdown(commentId, 'deleteCommentButton' + commentId)"
        *ngIf="commentDeleteStillValid || user?.isSuperUser"
      >
        <img [src]="EIconPath.MISC_MORE" class="deleteComment__icon" />
      </div>

      <div
        ppTooltip
        ppTitle="Comments cannot be changed after 48 hours"
        [id]="'deleteCommentButton' + commentId"
        class="deleteComment--disabled"
        *ngIf="!commentDeleteStillValid && !user.isSuperUser"
      >
        <img [src]="EIconPath.MISC_MORE" class="deleteComment__icon" />
      </div>
    </div>
  </div>
</div>
