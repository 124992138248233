<ng-container *ngIf="added.length > 0">
  <span>
    <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>
    {{ 'the_following' | ppTranslate }}
    <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
    {{ 'to_a_point_you' | ppTranslate }}
    {{ notificationReason }}:
  </span>

  <div class="notification__tags">
    <ng-container *ngFor="let item of added; let last = last">
      <span>{{ item }} <span *ngIf="!last">, </span></span>
    </ng-container>
  </div></ng-container
>

<ng-container *ngIf="removed.length > 0">
  <span>
    <span class="notification__blue-text">{{ 'removed' | ppTranslate }}</span>
    {{ 'the_following' | ppTranslate }}
    <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}:
  </span>

  <div>
    <ng-container *ngFor="let item of removed; let last = last">
      <span>{{ item }} <span *ngIf="!last">, </span></span>
    </ng-container>
  </div>
</ng-container>
