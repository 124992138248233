<div class="multiListDropdown" [ngStyle]="{ width: dropdownWidth }">
  <pp-search-box
    [ppFocusOnInit]="true"
    class="dropdown__searchBar"
    [ppPlaceholder]="'select_list_item' | ppTranslate"
    (ppAction)="setSearchBox($event)"
    ppSize="wide"
  ></pp-search-box>

  <pp-custom-field-multi-list-select
    [ppItem]="field"
    [ppSelectedIds]="selectedIds"
    (ppSelect)="select($event)"
    [ppFilter]="filterListItemsBy"
    class="listCF__wrapper"
    (ppOnScroll)="handleScroll($event)"
  ></pp-custom-field-multi-list-select>
</div>

<footer class="usersDropdown__footer">
  <div *ngIf="!scrolledToBottom" class="optionsDropdown__shadow">&nbsp;</div>

  <div class="optionsDropdown__shadow-footer-buttons">
    <pp-button ppStyle="secondary" (ppAction)="clearList()">
      {{ 'clear_all' | ppTranslate }}
    </pp-button>

    <pp-button ppStyle="primary" (ppAction)="hideDropdown()">
      {{ 'done' | ppTranslate }}
    </pp-button>
  </div>
</footer>
