import { Component, Input, OnChanges } from '@angular/core';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';

@Component({
  selector: 'pp-share-edit-site-display',
  templateUrl: './share-edit-site-display.component.html',
  styleUrl: './share-edit-site-display.component.scss',
})
export class ShareEditSiteDisplayComponent implements OnChanges {
  @Input() ppWorkspaceId: string;

  workspaceName: string;

  constructor(private workspaceService: WorkspaceService) {}

  ngOnChanges(): void {
    const workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);
    this.workspaceName = workspace.siteName;
  }
}
