import { Component, Input, OnChanges } from '@angular/core';
import { UsersService } from 'src/app/project/modules/users/users.service';

@Component({
  selector: 'pp-account-user-modal-shares-list-share-user',
  templateUrl: './account-user-modal-shares-list-share-user.component.html',
  styleUrl: './account-user-modal-shares-list-share-user.component.scss',
})
export class AccountUserModalSharesListShareUserComponent implements OnChanges {
  @Input() ppUserId: string;

  userName: string;
  userAvatarSrc: string;

  constructor(private usersService: UsersService) {}

  ngOnChanges(): void {
    const user = this.usersService.getUser(this.ppUserId);

    this.userName = user.userName;
    this.userAvatarSrc = user.avatarPublicUrl;
  }
}
