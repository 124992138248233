<div class="notification__response" *ngIf="ppRespondingToComment">
  <div class="notification__new-comment">
    <div class="notification__user-avatar-wrapper">
      <img
        class="notification__user-avatar"
        [ppSecureSrc]="'api/v1/images/' + activeUser.avatarId + '/file/size/square/40'"
      />
    </div>

    <pp-rich-text
      *ngIf="ppRespondingToComment"
      [ppValue]="{ richText: ppNewComment.commentRich, plainText: ppNewComment.comment }"
      [ppId]="'notificationResponse' + ppNotificationId"
      [ppCanEdit]="!ppCommentSent"
      [ppWorkspaceId]="ppNotification.workspaceId"
      ppPlaceholder="Enter comment..."
      (ppUpdate)="comment($event)"
      (ppCancel)="closeRespondComment($event)"
      (ppUpdateUserList)="updateUserList()"
      [ppUserListIds]="userListIds"
      [ppRichTextOptions]="richTextOptions"
    ></pp-rich-text>
  </div>
</div>
