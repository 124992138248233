import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TCustomFieldFilter, TFilters } from '../site-filter.model';

export function clearCustomFieldFilter(
  applied: boolean,
  field: TCustomFieldFilter,
  customFields: TAllCustomFields,
  filters: TFilters,
): TCustomFieldFilter[] {
  if (customFields[field.workspaceId] && customFields[field.workspaceId][field.id]?.display) {
    const index = filters.customFields.findIndex(
      (filterCustomField) =>
        customFields[filterCustomField.workspaceId][filterCustomField.id] ===
        customFields[field.workspaceId][field.id],
    );

    filters.customFields[index].excluded = false;

    switch (customFields[field.workspaceId][field.id].type) {
      case ECustomFieldType.COST:
      case ECustomFieldType.PERCENTAGE:
      case ECustomFieldType.FORMULA:
      case ECustomFieldType.NUMBERS:
      case ECustomFieldType.TIME:
        filters.customFields[index].min = null;
        filters.customFields[index].max = null;

        if (applied) {
          filters.customFields[index].appliedValues.min = null;
          filters.customFields[index].appliedValues.max = null;
        }

        break;
      case ECustomFieldType.CHECKBOX:
        filters.customFields[index].value = null;

        if (applied) {
          filters.customFields[index].appliedValues.value = null;
        }
        break;
      case ECustomFieldType.DATE:
      case ECustomFieldType.TIMELINE:
        filters.customFields[index].startDate = null;
        filters.customFields[index].endDate = null;

        if (applied) {
          filters.customFields[index].appliedValues.startDate = null;
          filters.customFields[index].appliedValues.endDate = null;
        }
        break;
      case ECustomFieldType.LIST:
        filters.customFields[index].idOfChosenElement = null;
        filters.customFields[index].path = null;
        filters.customFields[index].text = null;

        if (applied) {
          filters.customFields[index].appliedValues.idOfChosenElement = null;
          filters.customFields[index].appliedValues.path = null;
          filters.customFields[index].appliedValues.text = null;
        }
        break;

      case ECustomFieldType.MULTI_LIST:
        filters.customFields[index].multiListFilter = null;
        break;
    }
  }

  return filters.customFields;
}
