import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TNewComment } from 'src/app/project/data-providers/api-providers/comment-api-provider/comment-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TCommentData } from '../../../../comment-response.model';
import { PointsUpdateService } from '../../../../points-update.service';
import { PointActivityService } from '../../point-activity.service';

@Injectable({
  providedIn: 'root',
})
export class CommentActivityService {
  constructor(
    private pointsUpdateService: PointsUpdateService,
    private pointActivityService: PointActivityService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
  ) {}

  editComment({
    commentId,
    body,
    workspaceId,
    pointId,
  }: {
    commentId: string;
    body: TNewComment;
    workspaceId: string;
    pointId: string;
  }): Observable<TCommentData> {
    return this.pointsUpdateService.editComment(commentId, body).pipe(
      tap(() => {
        const prompt = this.translationPipe.transform('prompt_comment_edited');
        this.promptService.showSuccess(prompt);

        this.pointActivityService.refreshTimeline(workspaceId, pointId, {
          refreshComments: true,
        });

        this.pointActivityService.scrollToTimelineHeading({ behavior: 'smooth', block: 'start' });
      }),
    );
  }
}
