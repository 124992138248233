import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { logErrorInSentry } from '../../../errors/response-error';
import { DefaultShareService } from '../../../share/default-share.service';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TShare } from '../../../share/share.model';
import { TSharesByWorkspace } from './edit-account-user-modal.consts';

@Injectable({
  providedIn: 'root',
})
export class EditAccountUserModalDataService {
  private readonly _sharesBulkChange = new Subject<void>();
  readonly sharesBulkChange$ = this._sharesBulkChange.asObservable();

  private selectedWorkspaces: string[] = [];
  private emailList: string[] = [];
  private sharesMap: TSharesByWorkspace = {};
  private individualShares: TShare[] = [];

  private readonly _emailListChange$ = new Subject<string[]>();
  readonly emailListChange$ = this._emailListChange$.asObservable();

  constructor(private defaultShareService: DefaultShareService) {}

  updateWorkspacesData(selectedWorkspaces: string[]): void {
    this.setSelectedWorkspaces(selectedWorkspaces);
    this.filterSharesMap();
    this.filterIndividualShares();
  }

  getSelectedWorkspaces(): string[] {
    return this.selectedWorkspaces;
  }

  setEmailList(emailList: string[]): void {
    if (!isEqual(this.emailList, emailList)) {
      this.emailList = [...emailList];

      this._emailListChange$.next(emailList);
    }
  }

  getEmailList(): string[] {
    return this.emailList;
  }

  getSharesMap(): TSharesByWorkspace {
    return this.sharesMap;
  }

  getIndividualShares(): TShare[] {
    return this.individualShares;
  }

  setSharesMap(sharesMap: TSharesByWorkspace): void {
    this.sharesMap = sharesMap;

    this._sharesBulkChange.next();
  }

  setIndividualShares(individualShares: TShare[]): void {
    this.individualShares = individualShares;
  }

  setIndividualShare(share: TShare): void {
    const oldShareIndex = this.individualShares.findIndex(
      (oldShare) => oldShare.shareId === share.shareId,
    );

    this.individualShares[oldShareIndex] = share;
  }

  emitSharesChange(): void {
    this._sharesBulkChange.next();
  }

  getShare(workspaceId: string): TShare {
    return this.sharesMap[workspaceId];
  }

  setShare(workspaceId: string, share: TShare): void {
    if (workspaceId === null) {
      const error = new Error('workspaceId cannot be null');
      logErrorInSentry(error);
      throw error;
    }
    const oldShareIndex = this.individualShares.findIndex(
      (oldShare) => oldShare.shareId === share.shareId,
    );

    this.sharesMap[workspaceId] = share;
    this.individualShares[oldShareIndex] = share;

    this._sharesBulkChange.next();
  }

  clearData(): void {
    this.updateWorkspacesData([]);
    this.setEmailList([]);
    this.setSharesMap({});
    this.setIndividualShares([]);
  }

  filterSharesMap(): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      if (!this.selectedWorkspaces.includes(workspaceId)) {
        delete this.sharesMap[workspaceId];
      }
    });
  }

  filterIndividualShares(): void {
    this.individualShares.forEach((share, index) => {
      if (!this.selectedWorkspaces.includes(share.workspaceId)) {
        delete this.individualShares[index];
      }
    });
  }

  bulkChangeRole(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      const oldShare = this.sharesMap[workspaceId];

      const newShare = { ...oldShare, shareOption: share.shareOption };
      this.sharesMap[workspaceId] = this.defaultShareService.resetShareToDefaults(newShare);
    });

    this.individualShares.forEach((oldShare, index) => {
      const newShare = { ...oldShare, shareOption: share.shareOption };
      this.individualShares[index] = this.defaultShareService.resetShareToDefaults(newShare);
    });

    this._sharesBulkChange.next();
  }

  bulkChangeTags(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      const oldShare = this.sharesMap[workspaceId];

      if (oldShare.shareOption !== EUserRole.NORMAL && oldShare.shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.sharesMap[workspaceId].advancedAccessLevels.tags.permission = {
        read: share.advancedAccessLevels.tags.permission.read,
        edit: share.advancedAccessLevels.tags.permission.edit,
      };
    });

    this.individualShares.forEach((oldShare, index) => {
      const shareOption = oldShare.shareOption;

      if (shareOption !== EUserRole.NORMAL && shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.individualShares[index].advancedAccessLevels.tags.permission = {
        read: share.advancedAccessLevels.tags.permission.read,
        edit: share.advancedAccessLevels.tags.permission.edit,
      };
    });

    this._sharesBulkChange.next();
  }

  bulkChangeAccessibleTags(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      const oldShare = this.sharesMap[workspaceId];

      if (oldShare.shareOption !== EUserRole.NORMAL && oldShare.shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.sharesMap[workspaceId].defectTags = share.defectTags;
    });

    this.individualShares.forEach((oldShare, index) => {
      const shareOption = oldShare.shareOption;

      if (shareOption !== EUserRole.NORMAL && shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.individualShares[index].defectTags = share.defectTags;
    });

    this._sharesBulkChange.next();
  }

  bulkChangeActivity(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      const oldShare = this.sharesMap[workspaceId];

      if (oldShare.shareOption !== EUserRole.NORMAL && oldShare.shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.sharesMap[workspaceId].advancedAccessLevels.timeline.permission = {
        read: share.advancedAccessLevels.timeline.permission.read,
      };
    });

    this.individualShares.forEach((oldShare, index) => {
      const shareOption = oldShare.shareOption;

      if (shareOption !== EUserRole.NORMAL && shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.individualShares[index].advancedAccessLevels.timeline.permission = {
        read: share.advancedAccessLevels.timeline.permission.read,
      };
    });

    this._sharesBulkChange.next();
  }

  bulkChangeExports(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      const oldShare = this.sharesMap[workspaceId];

      if (oldShare.shareOption !== EUserRole.NORMAL && oldShare.shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.sharesMap[workspaceId].advancedAccessLevels.exports.permission = {
        read: share.advancedAccessLevels.exports.permission.read,
      };
    });

    this.individualShares.forEach((oldShare, index) => {
      const shareOption = oldShare.shareOption;

      if (shareOption !== EUserRole.NORMAL && shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.individualShares[index].advancedAccessLevels.exports.permission = {
        read: share.advancedAccessLevels.exports.permission.read,
      };
    });

    this._sharesBulkChange.next();
  }

  bulkChangeComments(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      const oldShare = this.sharesMap[workspaceId];

      if (oldShare.shareOption !== EUserRole.NORMAL && oldShare.shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.sharesMap[workspaceId].advancedAccessLevels.timeline.comments.permission = {
        read: share.advancedAccessLevels.timeline.comments.permission.read,
        edit: share.advancedAccessLevels.timeline.comments.permission.edit,
      };
    });

    this.individualShares.forEach((oldShare, index) => {
      const shareOption = oldShare.shareOption;

      if (shareOption !== EUserRole.NORMAL && shareOption !== EUserRole.LIMITED) {
        return;
      }

      this.individualShares[index].advancedAccessLevels.timeline.comments.permission = {
        read: share.advancedAccessLevels.timeline.comments.permission.read,
        edit: share.advancedAccessLevels.timeline.comments.permission.edit,
      };
    });

    this._sharesBulkChange.next();
  }

  private setSelectedWorkspaces(selectedWorkspaces: string[]): void {
    this.selectedWorkspaces = selectedWorkspaces;
  }
}
