import { Injectable } from '@angular/core';
import { WorkspaceApiProviderService } from '@core/api';
import { Observable, of, switchMap } from 'rxjs';
import {
  TComparedFormulas,
  TCompareFormulasBody,
} from 'src/app/project/data-providers/api-providers/workspace-api-provider/workspace-requests.model';
import { TWorkspace } from '../../workspace/workspace.model';
import { ECustomFieldType } from '../custom-field-types-enums';
import { TCustomField } from '../custom-fields.model';
import { CustomFieldsService } from '../custom-fields.service';
import { compareLists } from '../utils/compare-lists';
import { TCombinedCustomField } from './combined-custom-field.model';

@Injectable({
  providedIn: 'root',
})
export class CombineCustomFieldsService {
  constructor(
    private customFieldService: CustomFieldsService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
  ) {}

  private static groupedFormulas: TComparedFormulas = [];

  combineCustomFields(workspaces: TWorkspace[]): Observable<TCombinedCustomField[]> {
    const workspacesFormulas: TCompareFormulasBody = this.generateWorkspaceFormulasList(workspaces);

    return this.workspaceApiProviderService.compareCustomFieldFormulas(workspacesFormulas).pipe(
      switchMap((groupedFormulas) => {
        const combinedCustomFields: TCombinedCustomField[] = [];

        workspaces.forEach((workspace) => {
          const workspaceCustomFields =
            this.customFieldService.getCustomFields()[workspace.workspaceId];

          if (workspaceCustomFields) {
            Object.keys(workspaceCustomFields).forEach((customFieldId) => {
              const display = this.customFieldService.getWorkspaceCustomField(
                workspace.workspaceId,
                customFieldId,
              ).display;

              if (!display) {
                delete workspaceCustomFields[customFieldId];
              }
            });

            Object.keys(workspaceCustomFields).forEach((customFieldId) => {
              const customField = workspaceCustomFields[customFieldId];
              const match: TCombinedCustomField = this.compareCustomFields(
                customField,
                combinedCustomFields,
                groupedFormulas,
              );

              if (match) {
                match.combinedIds.push({
                  workspaceId: workspace.workspaceId,
                  customFieldId,
                });
              } else {
                combinedCustomFields.push({
                  ...customField,
                  combinedIds: [
                    {
                      workspaceId: workspace.workspaceId,
                      customFieldId,
                    },
                  ],
                });
              }
            });
          }
        });

        CombineCustomFieldsService.groupedFormulas = groupedFormulas;

        return of(combinedCustomFields);
      }),
    );
  }

  static getGroupedFormulas(): TComparedFormulas {
    return this.groupedFormulas;
  }

  private compareCustomFields(
    customField: TCustomField,
    combinedCustomFields: TCombinedCustomField[],
    groupedFormulas: TComparedFormulas,
  ): TCombinedCustomField {
    switch (customField.type) {
      case ECustomFieldType.DATE:
        return this.checkDateCustomField(combinedCustomFields, customField);
      case ECustomFieldType.COST:
        return this.checkCostCustomField(combinedCustomFields, customField);
      case ECustomFieldType.LIST:
      case ECustomFieldType.MULTI_LIST:
        return this.checkListCustomField(combinedCustomFields, customField);
      case ECustomFieldType.NUMBERS:
        return this.checkNumbersCustomField(combinedCustomFields, customField);
      case ECustomFieldType.TIME:
        return this.checkTimeCustomField(combinedCustomFields, customField);
      case ECustomFieldType.TIMELINE:
        return this.checkTimelineCustomField(combinedCustomFields, customField);
      case ECustomFieldType.PERCENTAGE:
        return this.checkPercentageCustomField(combinedCustomFields, customField);
      case ECustomFieldType.CHECKBOX:
        return this.checkCheckboxCustomField(combinedCustomFields, customField);
      case ECustomFieldType.TEXT:
        return this.checkTextCustomField(combinedCustomFields, customField);
      case ECustomFieldType.RICHTEXT:
        return this.checkRichTextCustomField(combinedCustomFields, customField);
      case ECustomFieldType.FORMULA:
        return this.checkFormulaCustomField(combinedCustomFields, customField, groupedFormulas);
      default:
        throw new Error('Custom field type not found ' + customField.type);
    }
  }

  private checkDateCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkCostCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find(
      (combinedCustomField) =>
        combinedCustomField.label === customField.label &&
        combinedCustomField.type === customField.type &&
        combinedCustomField.currencyCode === customField.currencyCode &&
        combinedCustomField.subValuesActive === customField.subValuesActive,
    );
  }

  private checkListCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find((combinedCustomField) => {
      if (
        combinedCustomField.type !== customField.type ||
        combinedCustomField.label !== customField.label
      ) {
        return false;
      }

      return compareLists(combinedCustomField.subList, customField.subList);
    });
  }

  private checkNumbersCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find(
      (combinedCustomField) =>
        combinedCustomField.label === customField.label &&
        combinedCustomField.type === customField.type &&
        combinedCustomField.unit === customField.unit &&
        combinedCustomField.subValuesActive === customField.subValuesActive,
    );
  }

  private checkTimeCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkTimelineCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkPercentageCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find(
      (combinedCustomField) =>
        combinedCustomField.label === customField.label &&
        combinedCustomField.subValuesActive === customField.subValuesActive,
    );
  }

  private checkCheckboxCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkTextCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private checkFormulaCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
    groupedFormulas: TComparedFormulas,
  ): TCombinedCustomField {
    return combinedCustomFields.find((combinedCustomField) => {
      return CombineCustomFieldsService.compareByFormula(
        groupedFormulas,
        customField.id.toString(),
        combinedCustomField.combinedIds[0].customFieldId,
      );
    });
  }

  private checkRichTextCustomField(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return this.compareByName(combinedCustomFields, customField);
  }

  private compareByName(
    combinedCustomFields: TCombinedCustomField[],
    customField: TCustomField,
  ): TCombinedCustomField {
    return combinedCustomFields.find(
      (combinedCustomField) =>
        combinedCustomField.label === customField.label &&
        combinedCustomField.type === customField.type,
    );
  }

  static compareByFormula(
    groupedFormulas: TComparedFormulas,
    firstCustomFieldId: string,
    secondCustomFieldId: string,
  ): boolean {
    for (const sublist of groupedFormulas) {
      if (sublist.includes(firstCustomFieldId) && sublist.includes(secondCustomFieldId)) {
        return true;
      }
    }
    return false;
  }

  generateWorkspaceFormulasList(workspaces: TWorkspace[]) {
    const workspaceCustomFields: TCompareFormulasBody = {};

    workspaces.forEach((workspace) => {
      const customFields = workspace.customFields.filter((customFieldId) => {
        const customField = this.customFieldService.getWorkspaceCustomField(
          workspace.workspaceId,
          customFieldId,
        );

        return customField && customField.type === ECustomFieldType.FORMULA;
      });

      workspaceCustomFields[workspace.workspaceId] = customFields;
    });

    return workspaceCustomFields;
  }
}
