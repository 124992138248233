import { TCheckTransferResponse } from '@project/view-models';
import { translate } from 'src/app/project/features/translate/translate';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { ECustomFieldType } from '../../../custom-fields/custom-field-types-enums';
import { TFieldConflict } from '../site-options.model';

export const getFieldConflicts = (
  sireCheckingResponse: TCheckTransferResponse,
): TFieldConflict[] => {
  const fieldConflicts: TFieldConflict[] = [];

  if (sireCheckingResponse.missingCustomFields.COST) {
    sireCheckingResponse.missingCustomFields.COST.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.COST,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.DATE) {
    sireCheckingResponse.missingCustomFields.DATE.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.DATE,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.LIST) {
    sireCheckingResponse.missingCustomFields.LIST.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.LIST,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.MULTI_LIST) {
    sireCheckingResponse.missingCustomFields.MULTI_LIST.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.MULTI_LIST,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.TEXT) {
    sireCheckingResponse.missingCustomFields.TEXT.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.TEXT,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.RICHTEXT) {
    sireCheckingResponse.missingCustomFields.RICHTEXT.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.RICHTEXT,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.CHECKBOX) {
    sireCheckingResponse.missingCustomFields.CHECKBOX.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.CHECKBOX,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.TIME) {
    sireCheckingResponse.missingCustomFields.TIME.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.TIME,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.NUMBERS) {
    sireCheckingResponse.missingCustomFields.NUMBERS.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.NUMBERS,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.TIMELINE) {
    sireCheckingResponse.missingCustomFields.TIMELINE.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.TIMELINE,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.PERCENTAGE) {
    sireCheckingResponse.missingCustomFields.PERCENTAGE.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.PERCENTAGE,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingCustomFields.FORMULA) {
    sireCheckingResponse.missingCustomFields.FORMULA.forEach((field) => {
      fieldConflicts.push({
        type: ECustomFieldType.FORMULA,
        name: field,
      });
    });
  }

  if (sireCheckingResponse.missingTags.length) {
    fieldConflicts.push({
      type: EBasicField.TAGS,
      name: translate('tags'),
    });
  }

  return fieldConflicts;
};
