import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ECustomFieldType } from '../../../custom-fields/custom-field-types-enums';
import { EIntegrationStatus } from '../../../custom-fields/custom-fields.model';
import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { TColumn } from '../../site-table/columns/column.model';

export function getFieldIcon(column: TColumn): EIconPath {
  if (column.customFieldIds?.length > 0) {
    return getCustomFieldIcon(column.customFieldIds[0]);
  }

  return getBasicFieldIcon(column);
}

export function getCustomFieldIcon(customFieldId: string): EIconPath {
  const customFields = GET_CUSTOM_FIELDS();
  const customField = customFields[customFieldId];

  if (!customField) {
    return EIconPath.ICON_ACTIVITIES_TEXT_14;
  }

  if (customField.volyIntegrationActive === EIntegrationStatus.ON) {
    return EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL;
  } else if (customField.volyIntegrationActive === EIntegrationStatus.DISABLED) {
    return EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL;
  }

  switch (customField.type) {
    case ECustomFieldType.TEXT:
      return EIconPath.ICON_FIELDS_TEXT_14;
    case ECustomFieldType.LIST:
      return EIconPath.ICON_FIELDS_LIST_14;
    case ECustomFieldType.MULTI_LIST:
      return EIconPath.ICON_FIELDS_MULTI_LIST_14;
    case ECustomFieldType.DATE:
      return EIconPath.ICON_FIELDS_DATE_14;
    case ECustomFieldType.COST:
      return EIconPath.ICON_FIELDS_COST_14;
    case ECustomFieldType.NUMBERS:
      return EIconPath.ICON_FIELDS_NUMBERS_14;
    case ECustomFieldType.RICHTEXT:
      return EIconPath.ICON_FIELDS_RICH_TEXT_14;
    case ECustomFieldType.TIME:
      return EIconPath.ICON_FIELDS_TIME_14;
    case ECustomFieldType.PERCENTAGE:
      return EIconPath.ICON_FIELDS_PERCENTAGE_14;
    case ECustomFieldType.FORMULA:
      return EIconPath.ICON_FIELDS_FORMULA_14;
    case ECustomFieldType.CHECKBOX:
      return EIconPath.ICON_FIELDS_CHECKBOX_14;
    case ECustomFieldType.TIMELINE:
      return EIconPath.ICON_FIELDS_TIMELINE_14;
  }
}

function getBasicFieldIcon(column: TColumn): EIconPath {
  switch (column.name) {
    case EBasicField.ACCOUNT:
    case EBasicField.SITE:
    case EBasicField.DESCRIPTION:
    case EBasicField.TITLE:
      return EIconPath.ICON_FIELDS_TEXT_14;
    case EBasicField.PRIORITY:
      return EIconPath.ICON_ACTIVITIES_PRIORITY_14;
    case EBasicField.STATUS:
      return EIconPath.ICON_ACTIVITIES_STATUS_14;
    case EBasicField.SEQUENCE_NUMBER:
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return EIconPath.ICON_FIELDS_NUMBERS_14;
    case EBasicField.CREATED:
    case EBasicField.UPDATED:
      return EIconPath.ICON_FIELDS_DATE_14;
    case EBasicField.CREATED_BY:
    case EBasicField.ASSIGNEES:
      return EIconPath.ICON_ACTIVITIES_USER_14;
    case EBasicField.TAGS:
      return EIconPath.ICON_ACTIVITIES_TAGS_14;
    case EBasicField.FLAGGED:
    case EBasicFieldShort.FLAGGED:
      return EIconPath.ICON_ACTIVITIES_RED_FLAG_14;
    default:
      return EIconPath.ICON_ACTIVITIES_TEXT_14;
  }
}
