<div class="activityWrapper">
  <span
    class="descriptionActivity"
    [class.descriptionActivity--comment]="ppComment"
    [class.descriptionActivity--collapsed]="!expanded"
    #description
  ></span>

  <span
    ppTooltip
    [ppTitle]="reactionTooltip"
    [ppSanitize]="false"
    [class.activityChange__like-comment--short]="!reaction || reaction?.like.length === 0"
    [ppOptions]="{ placement: 'bottom' }"
    class="activityChange__like-comment"
    (click)="likeComment()"
    *ngIf="ppComment"
  >
    <span class="activityChange__liked-panel__content">
      <img
        class="likeButton--secondary activityChange__liked-panel-icon"
        [class.activityChange__liked-panel-icon--single]="!reaction || reaction?.like.length === 0"
        [class.likeButton--liked]="reaction?.like.length > 0"
        [src]="EIconPath.INTERACTION_LIKE"
        *ngIf="!reaction?.like.includes(user.userId)"
      />

      <img
        class="likeButton--liked likeButton--secondary activityChange__liked-panel-icon"
        [src]="EIconPath.INTERACTION_LIKE_FILLED"
        *ngIf="reaction?.like.includes(user.userId)"
      />

      <p class="activityChange__liked-panel-text copySmall" *ngIf="reaction?.like.length > 0">
        {{ reaction.like.length }}
      </p>
    </span>
  </span>

  <div class="activityChange__show-more-less richTextActivity__options">
    <span class="ppLink" (click)="expandDescription()" *ngIf="!expanded && long">
      {{ 'show_more' | ppTranslate }}
    </span>

    <span class="ppLink" (click)="collapseDescription()" *ngIf="expanded && long">
      {{ 'show_less' | ppTranslate }}
    </span>

    <button *ngIf="ppShowEdit" class="commentEditButton" (click)="edit()">
      {{ 'edit' | ppTranslate }}
    </button>
  </div>
</div>
