<pp-modal [ppNoPadding]="true">
  <ng-container heading>
    {{ 'bulk_changes_custom_fields' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <ng-container *ngFor="let fieldId of workspace.customFields">
      <form
        *ngIf="editableCustomFieldIds.includes(fieldId) || allFieldsEditable"
        class="modal-section__container"
        name="bulkFieldsForm{{ customFieldsData[workspaceId][fieldId]?.label }} "
      >
        <ng-container [ngSwitch]="customFieldsData[workspaceId][fieldId].type">
          <pp-bulk-field-text
            *ngSwitchCase="customFieldTypes.TEXT"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
          ></pp-bulk-field-text>

          <pp-bulk-field-date
            *ngSwitchCase="customFieldTypes.DATE"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
          ></pp-bulk-field-date>

          <pp-bulk-field-list
            *ngSwitchCase="customFieldTypes.LIST"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
          ></pp-bulk-field-list>

          <pp-bulk-field-multi-list
            *ngSwitchCase="customFieldTypes.MULTI_LIST"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
          ></pp-bulk-field-multi-list>

          <pp-bulk-field-cost
            *ngSwitchCase="customFieldTypes.COST"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
          ></pp-bulk-field-cost>

          <pp-bulk-field-percentage
            *ngSwitchCase="customFieldTypes.PERCENTAGE"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
            [ppWorkspaceId]="workspaceId"
          ></pp-bulk-field-percentage>

          <pp-bulk-field-timeline
            *ngSwitchCase="customFieldTypes.TIMELINE"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
            [ppWorkspaceId]="workspaceId"
          ></pp-bulk-field-timeline>

          <pp-bulk-field-time
            *ngSwitchCase="customFieldTypes.TIME"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
            [ppWorkspaceId]="workspaceId"
          ></pp-bulk-field-time>

          <pp-bulk-field-numbers
            *ngSwitchCase="customFieldTypes.NUMBERS"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
            [ppWorkspaceId]="workspaceId"
          ></pp-bulk-field-numbers>

          <pp-bulk-field-rich-text
            *ngSwitchCase="customFieldTypes.RICHTEXT"
            [ppWorkspaceId]="workspace.workspaceId"
            [ppMentions]="mentions"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
          ></pp-bulk-field-rich-text>

          <pp-bulk-field-checkbox
            *ngSwitchCase="customFieldTypes.CHECKBOX"
            [ppWorkspaceId]="workspace.workspaceId"
            [ppCustomField]="customFieldsData[workspaceId][fieldId]"
            [(ppCustomFieldsExtended)]="customFieldsExtended"
            [(ppCustomFields)]="customFields"
            [ppFieldId]="fieldId"
          ></pp-bulk-field-checkbox>
        </ng-container>
      </form>
    </ng-container>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
