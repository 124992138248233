import { cloneDeep } from 'lodash';
import { ECustomFieldType } from '../custom-field-types-enums';
import {
  EIntegrationStatus,
  TCustomField,
  TCustomFieldDTO,
  TCustomFieldList,
} from '../custom-fields.model';

export class CustomFieldModelFactory {
  static createFromDTO(response: TCustomFieldDTO | TCustomField): TCustomField {
    const dto = cloneDeep(response);

    let showTotal: boolean = dto.showTotal;
    let showCommas: boolean = dto.showCommas;
    let display = dto.display;
    let lockedTemplate = dto.lockedTemplate;
    let lockedValue = dto.lockedValue;
    let volyIntegrationActive: EIntegrationStatus = null;
    let subValuesActive = dto.subValuesActive;

    if (dto.showTotal) {
      if ((dto.showTotal as any) === 'true') {
        showTotal = true;
      } else if ((dto.showTotal as any) === 'false') {
        showTotal = false;
      }
    }

    if (dto.showCommas) {
      if ((dto.showCommas as any) === 'true') {
        showCommas = true;
      } else if ((dto.showCommas as any) === 'false') {
        showCommas = false;
      }
    }

    if (dto.decimalPlaces === undefined && dto.type === ECustomFieldType.COST) {
      dto.decimalPlaces = 2;
    }

    if (dto.display === undefined) {
      display = true;
    }

    if (dto.lockedTemplate === undefined) {
      lockedTemplate = false;
    }

    if (dto.lockedValue === undefined) {
      lockedValue = false;
    }

    if (typeof dto.volyIntegrationActive === 'string') {
      volyIntegrationActive = dto.volyIntegrationActive as EIntegrationStatus;
    } else if (dto.volyIntegrationActive === false) {
      volyIntegrationActive = EIntegrationStatus.DISABLED;
    } else if (dto.volyIntegrationActive === true) {
      volyIntegrationActive = EIntegrationStatus.ON;
    } else {
      volyIntegrationActive = EIntegrationStatus.OFF;
    }

    if (dto.subValuesActive === undefined) {
      subValuesActive = false;
    }

    const customField: TCustomField = {
      label: dto.label,
      id: dto.id.toString(),
      subList: dto.subList ? convertSublistIdToString(dto.subList) : null,
      currencyCode: dto.currencyCode,
      currencySymbol: dto.currencySymbol,
      type: dto.type,
      unit: dto.unit,
      decimalPlaces: dto.decimalPlaces,
      showTotal,
      showCommas,
      showHoursOnly: dto.showHoursOnly,
      fieldError: false,
      workspaceId: dto.workspaceId,
      lockedTemplate,
      lockedValue,
      display,
      volyIntegrationActive,
      outputType: dto.outputType,
      formula: dto.formula,
      nestingLevel: dto.nestingLevel,
      subValuesActive,
    };

    return customField;
  }
}

function convertSublistIdToString(list: TCustomFieldList[]): TCustomFieldList[] {
  return list.map((item) => ({
    id: item.id.toString(), // Convert the number id to string
    label: item.label,
    subList: item.subList ? convertSublistIdToString(item.subList) : undefined, // Recursively process subList
  }));
}
