<ng-container *ngFor="let field of ppFilters.customFields; trackBy: customFieldsTrackById">
  <ng-container
    *ngIf="
      customFields[field.workspaceId] &&
      customFields[field.workspaceId][field.id] &&
      customFields[field.workspaceId][field.id].type !== customFieldTypes.TEXT &&
      customFields[field.workspaceId][field.id].type !== customFieldTypes.RICHTEXT &&
      customFields[field.workspaceId][field.id].type !== customFieldTypes.TIME
    "
  >
    <div class="filterDropdown__section filterDropdown__section--customField">
      <div
        class="filterDropdown__heading"
        ppTooltip
        [ppTitle]="getTooltipTitle(customFields[field.workspaceId][field.id].volyIntegrationActive)"
      >
        <pp-toggle
          class="customField__toggle"
          ppStyle="filter"
          [ppChecked]="field.excluded"
          (ppAction)="setNoneCustomFieldFilter(field)"
        ></pp-toggle>

        <img
          *ngIf="
            customFields[field.workspaceId][field.id].volyIntegrationActive !==
            EIntegrationStatus.OFF
          "
          [src]="getIconSrc(customFields[field.workspaceId][field.id].volyIntegrationActive)"
        />

        <h6
          class="filterDropdown__heading--text"
          ppTooltip
          [ppTrimTooltip]="true"
          [ppTitle]="customFields[field.workspaceId][field.id].label"
          [ppOptions]="{ placement: 'right' }"
        >
          {{ customFields[field.workspaceId][field.id].label }}
        </h6>

        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_INFO"
          ppColor="grey-600"
          ppClass="setSize14"
          [ppSanitize]="false"
          ppTooltip
          [ppTitle]="field.tooltip ? field.tooltip : ('toggle_off_filter' | ppTranslate)"
          [ppMobileTooltip]="true"
        ></pp-icon>

        <button
          class="filterDropdown__clear--text"
          *ngIf="
            field.idOfChosenElement ||
            field.startDate ||
            field.endDate ||
            field.min ||
            field.max ||
            field.excluded ||
            field.multiListFilter?.value
          "
          (click)="clearCustomFieldFilter(field); applyFilters()"
          role="button"
        >
          {{ 'clear' | ppTranslate }}
        </button>
      </div>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.LIST"
      >
        <pp-filter-field-list
          [ppFilters]="ppFilters"
          [ppSelectedCF]="selectedCF"
          [ppField]="field"
          (ppApply)="applyFilters()"
          (ppSelectCf)="selectCustomField($event)"
        ></pp-filter-field-list>
        <hr />
      </ng-container>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.MULTI_LIST"
      >
        <pp-filter-field-multi-list
          [ppFilters]="ppFilters"
          [ppSelectedCF]="selectedCF"
          [ppField]="field"
          (ppApply)="applyFilters()"
          (ppSelectCf)="selectCustomField($event)"
        ></pp-filter-field-multi-list>
        <hr />
      </ng-container>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.COST"
      >
        <pp-filter-field-cost
          [ppCustomFields]="customFields"
          [ppField]="field"
          (ppApply)="applyFilters()"
        ></pp-filter-field-cost>

        <hr />
      </ng-container>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.NUMBERS"
      >
        <pp-filter-field-numbers
          [ppCustomFields]="customFields"
          [ppField]="field"
          (ppApply)="applyFilters()"
        ></pp-filter-field-numbers>

        <hr />
      </ng-container>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.PERCENTAGE"
      >
        <pp-filter-field-percentage
          [ppCustomFields]="customFields"
          [ppField]="field"
          (ppApply)="applyFilters()"
        ></pp-filter-field-percentage>

        <hr />
      </ng-container>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.CHECKBOX"
      >
        <pp-filter-field-checkbox
          [ppField]="field"
          (ppApply)="applyFilters()"
        ></pp-filter-field-checkbox>

        <hr />
      </ng-container>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.DATE"
      >
        <pp-filter-field-date
          [ppFilters]="ppFilters"
          [ppCustomFields]="customFields"
          [ppField]="field"
          (ppApply)="applyFilters()"
        ></pp-filter-field-date>

        <hr />
      </ng-container>

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.TIMELINE"
      >
        <pp-filter-field-timeline
          [ppFilters]="ppFilters"
          [ppCustomFields]="customFields"
          [ppField]="field"
          (ppApply)="applyFilters()"
        ></pp-filter-field-timeline>

        <hr />
      </ng-container>

      <!-- TODO Formula -->

      <ng-container
        *ngIf="customFields[field.workspaceId][field.id].type === customFieldTypes.FORMULA"
      >
        <pp-filter-field-formula
          [ppCustomFields]="customFields"
          [ppField]="field"
          (ppApply)="applyFilters()"
        ></pp-filter-field-formula>

        <hr />
      </ng-container>
    </div>
  </ng-container>
</ng-container>
